/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import { AudienceStage } from './Content';
import { SaveStatus } from './ContentContainer';
import { AudienceProps, AudienceType, FormErrors, newStaffAudienceProps, newStudentAudienceProps } from '../../../../models/FormProps';
import { isEmpty } from '../../../../utils/equality';

interface Props {
  formsUrl: string;
  activeStep: AudienceStage;
  saveStatus: SaveStatus;
  viewableAudience: AudienceProps;
  setActiveStepCallback: (stage: AudienceStage) => void;
  updateViewableAudienceCallback: (viewableAudience: AudienceProps) => void;
  formErrors: FormErrors;
  viewOnly: boolean;
  expectedAudience?: AudienceType;
}

enum BreadcrumbCopy {
  Home = 'Home',
  SelectAudienceType = 'Select Audience Type',
  AccessType = 'Access Type',
  Personalisation = 'Personalisation',
  Staff = 'Staff',
  Students = 'Students'
}

export default function BreadcrumbHeader(props: Props): React.ReactElement {
  const {
    formsUrl,
    saveStatus,
    activeStep,
    viewableAudience,
    setActiveStepCallback,
    updateViewableAudienceCallback,
    formErrors,
    viewOnly,
    expectedAudience
  } = props;

  const TEXT_COLOR = '#263238';
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const commonBreadcrumbLinkProps: LinkProps = {
    underline: 'hover',
    sx: { cursor: 'pointer', color: TEXT_COLOR, fontSize: isMobileOrTablet ? '14px' : '16px' }
  };

  const isUnrestrictedAudience = !expectedAudience;

  return (
    <Box paddingY={2} bgcolor={activeStep === AudienceStage.PersonaliseFields ? '#FDFDFD' : 'transparent'}>
      <Container>
        {renderContent()}
      </Container>
    </Box>
  );

  function renderSelectAudienceBreadcrumb() {
    if (isUnrestrictedAudience) {
      return (
        <Link {...commonBreadcrumbLinkProps} onClick={() => setActiveStepCallback(AudienceStage.SelectAudienceType)}>
          {BreadcrumbCopy.SelectAudienceType}
        </Link>
      );
    }

    return <Typography {...commonBreadcrumbLinkProps}>{BreadcrumbCopy.SelectAudienceType}</Typography>;
  }

  function renderAccessTypeBreadcrumb() {
    if (isUnrestrictedAudience) {
      return (
        <Link {...commonBreadcrumbLinkProps} onClick={() => setActiveStepCallback(AudienceStage.SelectedTargetedOrUntargetedAudience)}>
          {BreadcrumbCopy.AccessType}
        </Link>
      );
    }

    return <Typography {...commonBreadcrumbLinkProps}>{BreadcrumbCopy.AccessType}</Typography>;
  }

  function renderContent() {
    switch (activeStep) {
      case AudienceStage.SelectAudienceType:
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Breadcrumbs aria-label="breadcrumb" maxItems={isMobileOrTablet ? 2 : 8}>
              <Link {...commonBreadcrumbLinkProps} href={formsUrl}>
                {BreadcrumbCopy.Home}
              </Link>
              <Typography {...commonBreadcrumbLinkProps}>{BreadcrumbCopy.SelectAudienceType}</Typography>
            </Breadcrumbs>

            {renderSaveStatus()}
          </Box>
        );

      case AudienceStage.SelectedTargetedOrUntargetedAudience:
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Breadcrumbs aria-label="breadcrumb" maxItems={isMobileOrTablet ? 2 : 8}>
              <Link {...commonBreadcrumbLinkProps} href={formsUrl}>
                {BreadcrumbCopy.Home}
              </Link>
              {renderSelectAudienceBreadcrumb()}
              <Typography {...commonBreadcrumbLinkProps}>Access Type</Typography>
            </Breadcrumbs>

            {renderSaveStatus()}
          </Box>
        );
      case AudienceStage.SelectIndividualsOrGroups:
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Breadcrumbs aria-label="breadcrumb" maxItems={isMobileOrTablet ? 2 : 8}>
              <Link {...commonBreadcrumbLinkProps} href={formsUrl}>
                {BreadcrumbCopy.Home}
              </Link>
              {renderSelectAudienceBreadcrumb()}
              {renderAccessTypeBreadcrumb()}
              <Typography fontSize={isMobileOrTablet ? '14px' : '16px'} color={TEXT_COLOR}>{viewableAudience.type === AudienceType.STAFF ? BreadcrumbCopy.Staff : BreadcrumbCopy.Students}</Typography>
            </Breadcrumbs>

            <Stack direction="row" gap={1}>

              {renderSaveStatus()}
              {
                !isMobileOrTablet && (
                  <Button
                    variant="outlined"
                    onClick={removeAudienceEntities}
                    disabled={viewOnly}
                    size="medium"
                  >
                    CLEAR ALL
                  </Button>

                )
              }

            </Stack>
          </Box>
        );

      case AudienceStage.PersonaliseFields:
        return (
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Breadcrumbs aria-label="breadcrumb" maxItems={2}>
              <Link {...commonBreadcrumbLinkProps} href={formsUrl}>
                {BreadcrumbCopy.Home}
              </Link>
              {renderSelectAudienceBreadcrumb()}
              {renderAccessTypeBreadcrumb()}
              <Link {...commonBreadcrumbLinkProps} onClick={() => setActiveStepCallback(AudienceStage.SelectIndividualsOrGroups)}>
                {viewableAudience.type === AudienceType.STAFF ? BreadcrumbCopy.Staff : BreadcrumbCopy.Students }
              </Link>
              <Typography fontSize={isMobileOrTablet ? '14px' : '16px'} color={TEXT_COLOR}>{BreadcrumbCopy.Personalisation}</Typography>
            </Breadcrumbs>

            {renderSaveStatus()}
          </Box>
        );

      case AudienceStage.GoToQuestions:
        return null;

      default: {
        throw new Error(`Invalid audience stage: ${activeStep}!`);
      }
    }

    return null;
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        const defaultMsg = 'Please fix error(s) to save';
        return <Alert severity="error">{isEmpty(formErrors?.version) ? defaultMsg : formErrors.version}</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function removeAudienceEntities() {
    if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;

    switch (viewableAudience.type) {
      case AudienceType.STAFF: {
        updateViewableAudienceCallback(newStaffAudienceProps());
        break;
      }
      case AudienceType.STUDENT: {
        const studentAudienceProps = newStudentAudienceProps({ year: viewableAudience.year });
        updateViewableAudienceCallback(studentAudienceProps);
        break;
      }
      default:
      // Do nothing
    }
  }
}

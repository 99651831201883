import { Card, CardActionArea, CardContent, Chip, Stack, Typography, useTheme, Tooltip } from '@mui/material';
import { FormTemplateIcon } from 'components/models/FormTemplate';
import getWebkitLineClampProperties from 'components/utils/line_clamp';
import React from 'react';
import BooksWithAppleIllustration from '../../../../../../assets/images/illustrations/books-with-apple.svg';
import MotherChildHandIllustration from '../../../../../../assets/images/illustrations/mother-child-hand.svg';
import StudentIllustration from '../../../../../../assets/images/illustrations/student.svg';
import TeamIllustration from '../../../../../../assets/images/illustrations/team.svg';

interface Props {
  template: {
    title: string;
    description: string;
    icon: FormTemplateIcon;
    isNew: boolean,
    showFormTemplateUrl: string;
    author: string | null;
  };
}

const iconToIllustrationMap = Object.freeze({
  [FormTemplateIcon.BooksWithApple]: BooksWithAppleIllustration,
  [FormTemplateIcon.MotherChildHand]: MotherChildHandIllustration,
  [FormTemplateIcon.Student]: StudentIllustration,
  [FormTemplateIcon.Team]: TeamIllustration
});

export default function FormTemplateCard(props: Props) {
  const { template } = props;

  const theme = useTheme();

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea component="a" href={template.showFormTemplateUrl} sx={{ height: '100%' }}>
        <CardContent>
          <Stack direction="row" justifyContent="space-between">
            <img height={theme.spacing(6)} alt="" src={iconToIllustrationMap[template.icon]} />
            {template.isNew && <Chip label="New" color="primary" size="small" />}
          </Stack>
          <Typography
            variant="body1"
            fontWeight="bold"
            height={theme.spacing(3)}
            sx={getWebkitLineClampProperties(1)}
          >
            {template.title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            fontSize="12px"
            height={theme.spacing(2)}
            mb={theme.spacing(1)}
            sx={getWebkitLineClampProperties(1)}
          >
            {template.author && `By ${template.author}`}
          </Typography>
          <Tooltip title={template.description}>
            <Typography
              variant="body2"
              color="text.secondary"
              fontSize="14px"
              height={theme.spacing(5)}
              sx={getWebkitLineClampProperties(2)}
            >
              {template.description}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Alert, Box } from '@mui/material';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import FormProps, { FormErrors } from '../../../../../models/FormProps';
import { UnionElement } from '../../../../../form_elements/BaseElement';
import { LongTextQuestionType, LongTextQuestion } from '../../../../../form_elements/questions/long_text/LongTextQuestion';
import { RankingQuestion, RankingQuestionType } from '../../../../../form_elements/questions/ranking/RankingQuestion';
import LongTextImage from '../../../../../../../assets/images/form_elements/questions/long_text.svg';
import RankImage from '../../../../../../../assets/images/form_elements/questions/ranking.svg';
import MultipleResponseImage from '../../../../../../../assets/images/form_elements/questions/multiple_response.svg';
import MultipleChoiceImage from '../../../../../../../assets/images/form_elements/questions/multiple_choice.svg';
import SectionImage from '../../../../../../../assets/images/form_elements/section.svg';
import ImagesImage from '../../../../../../../assets/images/form_elements/images.svg';
import { MultipleResponseQuestion, MultipleResponseQuestionType } from '../../../../../form_elements/questions/multiple_response/MultipleResponseQuestion';
import { TitleEditor } from '../../../../../form_elements/title/TitleEditor';
import { TitleID } from '../../../../../form_elements/title/Title';
import { InstructionsID } from '../../../../../form_elements/instructions/Instructions';
import { InstructionsEditor } from '../../../../../form_elements/instructions/InstructionsEditor';
import { isEmpty } from '../../../../../utils/equality';
import { LongTextQuestionEditor } from '../../../../../form_elements/questions/long_text/LongTextQuestionEditor';
import { RankingQuestionEditor } from '../../../../../form_elements/questions/ranking/RankingQuestionEditor';
import { MultipleResponseQuestionEditor } from '../../../../../form_elements/questions/multiple_response/MultipleResponseQuestionEditor';
import { findElement } from '../../../../../form_elements/RootElement';
import { SectionElement, SectionElementType } from '../../../../../form_elements/section/SectionElement';
import { ImageElement, ImageElementType } from '../../../../../form_elements/image/ImageElement';
import { SectionElementEditor } from '../../../../../form_elements/section/SectionElementEditor';
import ImageElementEditor from '../../../../../form_elements/image/ImageElementEditor';
import AudienceMetadata from '../../../../../models/AudienceMetadata';
import { MultipleChoiceQuestion, MultipleChoiceQuestionType } from '../../../../../form_elements/questions/multiple_choice/MultipleChoiceQuestion';
import { MultipleChoiceQuestionEditor } from '../../../../../form_elements/questions/multiple_choice/MultipleChoiceQuestionEditor';

interface Props {
  form: FormProps;
  formErrors: FormErrors;
  selectedElementID: string | null;
  metadata: AudienceMetadata;
  onAddMetadata: () => void;
  hasAdditionalMetadata: boolean;
  metadataValuesWithHeaderUrl: string;
  viewOnly: boolean;
  updateFormImageUrl?: string;
  onChangeTitle(title: string);
  onChangeInstructions(instructions: string);
  onUpdateElement: (element: UnionElement) => void;
  onCloneElement: (element: UnionElement) => void;
  onDeleteElement: (elementID: string) => void;
  onNewElement: (elementType: string) => void;
  template: TemplateUsageInstructions;
}

enum SelectedTab {
  UsageInfo = 'usageInfo',
  Elements = 'elements',
  ElementSettings = 'elementSettings'
}

export default function Editor(props: Props) {
  const { form, formErrors, selectedElementID, metadata, hasAdditionalMetadata, onAddMetadata, metadataValuesWithHeaderUrl, viewOnly, updateFormImageUrl,
    onChangeTitle, onChangeInstructions, onUpdateElement, onCloneElement, onDeleteElement, onNewElement, template } = props;

  const [selectedTab, setSelectedTab] = useState(isEmpty(template.usageInstructions) ? SelectedTab.Elements : SelectedTab.UsageInfo);

  useEffect(() => {
    setSelectedTab(currSelectedTab => {
      if (currSelectedTab === SelectedTab.UsageInfo) return currSelectedTab;

      return isEmpty(selectedElementID) ? SelectedTab.Elements : SelectedTab.ElementSettings;
    });
  }, [selectedElementID]);

  return (
    <section className="editor">
      {renderTabs()}
      {renderTabContent()}
    </section>
  );

  function renderTabs() {
    return (
      <Tabs value={selectedTab} onChange={(_event, newTab) => setSelectedTab(newTab)}>
        {template.usageInstructions && <Tab value={SelectedTab.UsageInfo} label="USAGE INFO" /> }
        <Tab value={SelectedTab.Elements} label="NEW" />
        {selectedElementID && <Tab value={SelectedTab.ElementSettings} label="EDIT" />}
      </Tabs>
    );
  }

  function renderTabContent() {
    switch (selectedTab) {
      case SelectedTab.Elements:
        return renderElements();
      case SelectedTab.ElementSettings:
        return isEmpty(selectedElementID) ? renderElements() : renderSelectedElement();
      case SelectedTab.UsageInfo:
        return renderUsageInfo();
      default: return null;
    }
  }

  function renderElements() {
    return (
      <Stack className="elements" direction="row">
        <Button onClick={() => onNewElement(MultipleChoiceQuestionType)} disabled={viewOnly}>
          <img alt="Multiple choice question" src={MultipleChoiceImage} />
        </Button>

        <Button onClick={() => onNewElement(RankingQuestionType)} disabled={viewOnly}>
          <img alt="Ranking question" src={RankImage} />
        </Button>

        <Button onClick={() => onNewElement(LongTextQuestionType)} disabled={viewOnly}>
          <img alt="Free text question" src={LongTextImage} />
        </Button>

        <Button onClick={() => onNewElement(MultipleResponseQuestionType)} disabled={viewOnly}>
          <img alt="Multiple response question" src={MultipleResponseImage} />
        </Button>

        <Button onClick={() => onNewElement(ImageElementType)} disabled={viewOnly}>
          {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
          <img alt="Image" src={ImagesImage} />
        </Button>

        <Button onClick={() => onNewElement(SectionElementType)} disabled={viewOnly}>
          <img alt="Section" src={SectionImage} />
        </Button>
      </Stack>
    );
  }

  function renderSelectedElement() {
    if (selectedElementID === TitleID) {
      return <TitleEditor title={form.title} errors={formErrors.title} onChangeTitle={onChangeTitle} viewOnly={viewOnly} />;
    } else if (selectedElementID === InstructionsID) {
      return (
        <InstructionsEditor
          instructions={form.instructions}
          errors={formErrors.instructions}
          onChangeInstructions={onChangeInstructions}
          metadataHeaders={metadata.headers}
          onAddMetadata={onAddMetadata}
          hasAdditionalMetadata={hasAdditionalMetadata}
          viewOnly={viewOnly}
        />
      );
    }

    const element = findElement(form.body, selectedElementID);
    const editorProps = {
      form,
      metadata,
      onAddMetadata,
      hasAdditionalMetadata,
      metadataValuesWithHeaderUrl,
      errors: formErrors.bodyByElementID?.[selectedElementID],
      viewOnly,
      onUpdate: onUpdateElement,
      onClone: onCloneElement,
      onDelete: onDeleteElement
    };

    switch (element.type) {
      case LongTextQuestionType: {
        return <LongTextQuestionEditor element={element as LongTextQuestion} {...editorProps} />;
      }
      case RankingQuestionType: {
        return <RankingQuestionEditor element={element as RankingQuestion} {...editorProps} />;
      }
      case MultipleResponseQuestionType: {
        return <MultipleResponseQuestionEditor element={element as MultipleResponseQuestion} {...editorProps} />;
      }
      case MultipleChoiceQuestionType: {
        return <MultipleChoiceQuestionEditor element={element as MultipleChoiceQuestion} {...editorProps} />;
      }
      case ImageElementType: {
        return <ImageElementEditor element={element as ImageElement} updateFormImageUrl={updateFormImageUrl} {...editorProps} />;
      }
      case SectionElementType: {
        return <SectionElementEditor element={element as SectionElement} {...editorProps} />;
      }
      default: {
        return null;
      }
    }
  }

  function renderUsageInfo() {
    return (
      <Box p="24px">
        <Alert
          severity="info"
          sx={{
            fontSize: '16px',
            whiteSpace: 'pre-line',
            color: 'text.secondary',
            fontWeight: 400
          }}
        >
          {template.author && `This form template is created by ${template.author}\n\n`}
          {template.usageInstructions}
        </Alert>
      </Box>
    );
  }
}

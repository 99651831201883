import { Box, Stack, useTheme } from '@mui/material';
import React, { useState } from 'react';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import FormProps, { FormErrors } from '../../../../models/FormProps';
import { FormTemplate } from '../../../../models/FormTemplate';
import Form from '../../forms/edit_questions/form/Form';
import Editor from '../../forms/edit_questions/editor/Editor';

interface Props {
  formTemplate: FormTemplate;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
}

// For the templates page, the form shouldn't be updated.
// TODO: instead of having a function that do nothing,
// can see if it makes sense for the underlying Editor component
// to take in these props as undefined instead (or define "variants"
// of the Editor component to convey the different types of editor used
// e.g. read-only for collaborators, template viewing, real editing, etc)
// eslint-disable-next-line @typescript-eslint/no-empty-function
const DUMMY_FUNCTION = () => {};

export default function Content(props: Props): React.ReactElement {
  const { formTemplate, metadata, metadataValuesWithHeaderUrl } = props;

  const theme = useTheme();
  const [selectedElementID, setSelectedElementID] = useState<string>(null);

  const form = {
    title: formTemplate.title,
    instructions: formTemplate.formInstructions,
    body: formTemplate.body
  } as FormProps;

  return (
    <Box
      component="main"
      className="manage-forms-edit-page-content manage-forms-edit-questions-page-content"
      height="100%"
    >
      <Stack
        direction="row"
        alignItems="flex-start"
        justifyContent="space-between"
        paddingTop={theme.spacing(2)}
        height={`calc(100vh - 100px - ${theme.spacing(2)})`} // minus 100px for topbar and theme.spacing(2) for padding
      >
        <Form
          form={form}
          formErrors={{} as FormErrors}
          selectedElementID={selectedElementID}
          selectedElementRefCallback={null}
          onSelectElement={setSelectedElementID}
          onReorderElement={DUMMY_FUNCTION}
          viewOnly
          isTemplateView
        />

        <Editor
          form={form}
          formErrors={{} as FormErrors}
          metadata={metadata}
          onAddMetadata={DUMMY_FUNCTION}
          metadataValuesWithHeaderUrl={metadataValuesWithHeaderUrl}
          hasAdditionalMetadata={false}
          viewOnly
          updateFormImageUrl={null}
          selectedElementID={selectedElementID}
          onChangeTitle={DUMMY_FUNCTION}
          onChangeInstructions={DUMMY_FUNCTION}
          onUpdateElement={DUMMY_FUNCTION}
          onCloneElement={DUMMY_FUNCTION}
          onDeleteElement={DUMMY_FUNCTION}
          onNewElement={DUMMY_FUNCTION}
          template={{
            usageInstructions: formTemplate.usageInstructions,
            userGuideUrl: formTemplate.userGuideUrl,
            author: formTemplate.author
          }}
        />
      </Stack>
    </Box>
  );
}

/* eslint-disable import/prefer-default-export */
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import QRCode from 'qrcode';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import FormHelperText from '@mui/material/FormHelperText';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { FormConfigProps, FormUpdateResponseData, UpdateScheduleProps, UpdateScheduleResponse } from '../../../../../models/FormProps';
import { getCsrfToken } from '../../../../../utils/csrf';
import ConfigurePublish from './ConfigurePublish';

interface ShareModalProps {
  formName: string;
  formUrl: string;
  createAnnouncementOnPgUrl: string;
  formConfig: FormConfigProps;
  onUpdateFormConfig: (data: FormConfigProps) => Promise<FormUpdateResponseData | null>;
  onUpdateSchedule: (data: UpdateScheduleProps) => Promise<UpdateScheduleResponse | null>;
  onCloseModal: () => void;
  viewOnly: boolean;
  isShareableToPG: boolean;
  formPublishErrors: Array<string>;
}

enum PublishShareTab {
  Publish,
  Share
}

export function PublishShareModal(props: ShareModalProps): React.ReactElement {
  const {
    formName,
    formConfig,
    formUrl,
    createAnnouncementOnPgUrl,
    onUpdateFormConfig,
    onUpdateSchedule,
    onCloseModal,
    viewOnly,
    isShareableToPG,
    formPublishErrors
  } = props;

  const [qrCode, setQRCode] = useState(null);
  const [isSendingToPG, setIsSendingToPG] = useState(false);
  const [pgErrorMessage, setPGErrorMessage] = useState('');
  const qrCodeRef = useRef<HTMLAnchorElement>();
  const dialogRef = useRef(null);

  const [currentTab, setCurrentTab] = useState<PublishShareTab>(PublishShareTab.Publish);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    QRCode.toDataURL(formUrl, { width: 500, scale: 8 }, (err, url) => {
      if (url) setQRCode(url);
    });
  }, []);

  return (
    <Dialog
      open
      onClose={onCloseModal}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          position: 'absolute',
          top: 'calc(10vh - 32px)', // 32px for the margin around the paper
          width: '744px',
          maxWidth: 'xl',
          maxHeight: '80vh',
          overflowY: 'auto',
          overflowX: 'hidden'
        }
      }}
    >
      <DialogContent ref={dialogRef} sx={{ p: 0, overflowY: 'auto' }}>
        <Box
          sx={{
            width: '100%',
            borderColor: 'divider',
            backgroundColor: '#F5F7FA',
            position: 'sticky',
            top: 0, // Keep the tabs sticky at the top
            zIndex: 1 // Ensure it stays above the content when scrolling
          }}
        >
          <Tabs value={currentTab} onChange={handleTabChange} sx={{ pl: 2, pr: 2, pt: 2 }}>
            <Tab label="Publish" />
            <Tab label="Share" />
          </Tabs>
        </Box>

        <Box sx={{ pl: 3, pr: 3, pb: 3 }}>
          <TabPanel index={PublishShareTab.Publish} currentTab={currentTab}>
            <ConfigurePublish
              formConfig={formConfig}
              onUpdateFormConfig={onUpdateFormConfig}
              onUpdateSchedule={onUpdateSchedule}
              viewOnly={viewOnly}
              formPublishErrors={formPublishErrors}
            />
          </TabPanel>

          <TabPanel index={PublishShareTab.Share} currentTab={currentTab}>
            <Stack spacing={3}>
              <Typography variant="h5" fontSize="24px" fontWeight="600">Share</Typography>

              {isShareableToPG && renderShareOnPG()}

              {isShareableToPG && <Typography variant="h6" fontSize="24px" fontWeight="600">or</Typography>}

              <Box>
                <Typography fontSize="16px">Direct form link or QR code</Typography>

                <Stack direction="row" alignItems="center">
                  <TextField fullWidth size="small" value={formUrl} />
                  <Button variant="contained" size="large" sx={{ marginLeft: '15px', marginRight: '5px' }} onClick={onClickCopy}>COPY</Button>
                  {qrCode && (
                    <a ref={qrCodeRef} href={qrCode} download={`${formName} QR.png`}>
                      <img alt="QR code for this form" src={qrCode} width="50" />
                    </a>
                  )}
                </Stack>
              </Box>
            </Stack>
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );

  function onClickCopy() {
    window.navigator.clipboard.writeText(formUrl);
  }

  function renderShareOnPG() {
    return (
      <Box>
        <Typography marginBottom="8px" fontSize="16px">As Announcement on Parents Gateway</Typography>
        <Button
          variant="outlined"
          size="large"
          disabled={isSendingToPG}
          endIcon={isSendingToPG ? <CircularProgress color="inherit" size={20} /> : <OpenInNewIcon />}
          onClick={shareOnPg}
        >
          SHARE ON PARENTS GATEWAY
        </Button>
        {pgErrorMessage && <FormHelperText error>{pgErrorMessage}</FormHelperText>}
      </Box>
    );
  }

  function shareOnPg() {
    const requestOptions = {
      method: 'POST',
      headers: {
        'X-CSRF-Token': getCsrfToken()
      }
    };

    setIsSendingToPG(true);
    setPGErrorMessage('');

    return fetch(createAnnouncementOnPgUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data.link) {
          window.open(data.link);
        } else {
          setPGErrorMessage(data.error);
        }
      })
      .finally(() => setIsSendingToPG(false));
  }
}

function TabPanel(props: PropsWithChildren<{ index: number, currentTab: number }>) {
  const { children, currentTab, index } = props;

  return (
    <Box role="tabpanel" hidden={currentTab !== index}>
      {currentTab === index && <Stack spacing={3} pt={3}>{children}</Stack>}
    </Box>
  );
}

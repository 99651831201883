import React from 'react';
import classNames from 'classnames';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { Stack } from '@mui/material';
import { isNotEmpty } from '../../utils/equality';

export const TitleID = 'title';

interface TitleProps {
  title: string;
  editMode?: {
    errors?: Array<string>;
    isSelected: boolean;
    selectedElementRefCallback: (node: HTMLElement) => void;
    onSelectElement: (elementID: string) => void;
  };
}

export function Title(props: TitleProps) {
  const { title, editMode } = props;

  const className = classNames('form-element form-title-element selectable', { selected: editMode?.isSelected });

  return (
    <article className={className} onClick={() => editMode?.onSelectElement(TitleID)} ref={editMode?.isSelected ? editMode?.selectedElementRefCallback : null}>
      {editMode && (
        <header className="element-header">
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">FORM TITLE</Typography>
          </Stack>
          {isNotEmpty(editMode?.errors) && <Alert className="errors" severity="error">{editMode?.errors.length}</Alert>}
        </header>
      )}

      <h1 className="title">{title}</h1>
    </article>
  );
}

/* eslint-disable no-alert */
/* eslint-disable no-restricted-globals */
import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { Theme, useMediaQuery } from '@mui/material';
import { AudienceType, newUntargetedStudentAudienceProps, newStudentAudienceProps, UntargetedStudentAudienceProps, StudentAudienceProps } from '../../../../../models/FormProps';
import { isNotEmpty } from '../../../../../utils/equality';
import TargetedStudentImage from '../../../../../../../assets/images/targeted_untargeted_selector/targeted-student.svg';
import UntargetedStudentImage from '../../../../../../../assets/images/targeted_untargeted_selector/untargeted-student.svg';
import AudienceGuide from '../common/AudienceGuide';

interface Props {
  viewableAudience: StudentAudienceProps | UntargetedStudentAudienceProps;
  viewOnly: boolean;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps | UntargetedStudentAudienceProps) => void;
  formHasResponses: boolean;
  expectedAudience?: AudienceType;
}

export default function TargetedUntargetedStudentSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, updateViewableAudienceCallback, formHasResponses, expectedAudience } = props;
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  const isUnrestricetdAudience = !expectedAudience;

  return (
    <Stack spacing={5}>
      <Stack direction={isMobileOrTablet ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={3}>
        {(isUnrestricetdAudience || expectedAudience === AudienceType.STUDENT) && (
          <Button
            className={viewableAudience.type === AudienceType.STUDENT
              ? 'audience-editor-button-selected' : 'audience-editor-button'}
            variant="outlined"
            onClick={() => selectTargetedAudienceCallback()}
            disabled={viewOnly}
          >
            <img alt="Specific students only" src={TargetedStudentImage} className="audience-editor-button-image" />
          </Button>
        )}
        {(isUnrestricetdAudience || expectedAudience === AudienceType.UNTARGETED_STUDENT) && (
          <Button
            className={viewableAudience.type === AudienceType.UNTARGETED_STUDENT ? 'audience-editor-button-selected' : 'audience-editor-button'}
            variant="outlined"
            onClick={() => selectUntargetedAudienceCallback()}
            disabled={viewOnly}
          >
            <img alt="Any students" src={UntargetedStudentImage} className="audience-editor-button-image" />
          </Button>
        )}
      </Stack>
      <AudienceGuide />
    </Stack>
  );

  function selectTargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.UNTARGETED_STUDENT) {
      if (formHasResponses && !confirm('This will restrict the form audience to selected students. If you have already collected responses, make sure you select those students in the next page to continue viewing their responses. Click to proceed.')) return;
      updateViewableAudienceCallback(newStudentAudienceProps({}));
    }
  }

  function selectUntargetedAudienceCallback() {
    if (viewableAudience.type === AudienceType.STUDENT) {
      if (isNotEmpty(viewableAudience.entities) && !confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;
      updateViewableAudienceCallback(newUntargetedStudentAudienceProps());
    }
  }
}

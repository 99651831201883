import Alert from '@mui/material/Alert';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Stack, Theme, useMediaQuery } from '@mui/material';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import CustomTooltipAsPopover, { DefaultTooltipButton } from 'components/pages/common/CustomTooltipAsPopover';
import { getLocalStorageItem, LOCALSTORAGE_KEYS, setLocalStorageItem } from 'components/utils/local_storage';
import { findLastIndex } from 'components/utils/array';
import { newElement, UnionElement } from '../../../../form_elements/BaseElement';
import { isQuestionType } from '../../../../form_elements/questions/BaseQuestion';
import { RootElement, cloneElement, deleteElement, findElement, flattenRootElement, insertElement, parentElementOf, reorderElement, updateElement } from '../../../../form_elements/RootElement';
import { PublishShareModal } from './publishShareModal/PublishShareModal';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import { isEmpty, isNotEmpty } from '../../../../utils/equality';
import { InstructionsID } from '../../../../form_elements/instructions/Instructions';
import { SectionElement, SectionElementType } from '../../../../form_elements/section/SectionElement';
import { TitleID } from '../../../../form_elements/title/Title';
import FormProps, { FormConfigProps, FormErrors, FormUpdateResponseData, UpdateScheduleProps, UpdateScheduleResponse } from '../../../../models/FormProps';
import { SaveStatus } from './ContentContainer';
import Editor from './editor/Editor';
import Form from './form/Form';

interface Props {
  form: FormProps
  formConfig: FormConfigProps;
  template: TemplateUsageInstructions;
  formErrors: FormErrors;
  saveStatus: SaveStatus;
  metadata: AudienceMetadata;
  hasAdditionalMetadata: boolean;
  onAddMetadata: () => void;
  metadataValuesWithHeaderUrl: string;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  updateFormImageUrl: string;
  createAnnouncementOnPgUrl: string;
  onChangeForm: (form: FormProps) => void;
  onUpdateFormConfig: (data: FormConfigProps) => Promise<FormUpdateResponseData | null>,
  onUpdateSchedule: (data: UpdateScheduleProps) => Promise<UpdateScheduleResponse | null>,
  viewOnly: boolean;
  isShareableToPG: boolean;
  formPublishErrors: Array<string>;
  setIsExceedQuestionsLimitSnackbarOpenCallback: () => void;
}

const MAX_QUESTIONS_LIMIT = 100;
const TOOLTIP_ID = LOCALSTORAGE_KEYS.EDIT_QUESTION_PUBLISH_SHARE_MODAL_TOOLTIP;

export default function Content(props: Props) {
  const {
    form,
    formConfig,
    template: templateUsageInstructions,
    formErrors,
    saveStatus,
    metadata,
    onAddMetadata,
    hasAdditionalMetadata,
    metadataValuesWithHeaderUrl,
    previewUrl,
    formUrl,
    formsUrl,
    updateFormImageUrl,
    createAnnouncementOnPgUrl,
    onChangeForm,
    onUpdateFormConfig,
    onUpdateSchedule,
    viewOnly,
    isShareableToPG,
    formPublishErrors,
    setIsExceedQuestionsLimitSnackbarOpenCallback
  } = props;

  const [formState, setFormState] = useState<FormProps>(form);
  const [selectedElementID, setSelectedElementID] = useState<string>(null);
  const [showShareModal, setShowShareModal] = useState(false);

  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<HTMLElement | null>(null);
  const tooltipOpen = Boolean(tooltipAnchorEl);
  const tooltipId = tooltipOpen ? TOOLTIP_ID : undefined;

  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  useEffect(() => onChangeForm(formState), [formState]);

  // We store a ref to the ResizeObserver to allow clean up (see below) and prevent memory leaks.
  const selectedElementResizeObserverRef = useRef<ResizeObserver>(null);

  // We use a ref callback to automatically scroll the selected element into view
  // when it is selected (whether by the user or by adding a new question).
  const selectedElementRefCallback = useCallback((element: HTMLElement) => {
    // Nothing is selected.
    if (element === null) {
      // Clean up the resize observer connected to the previous selected element (if any).
      selectedElementResizeObserverRef.current?.disconnect();
      selectedElementResizeObserverRef.current = null;
      return;
    }

    const scrollOptions: ScrollIntoViewOptions = {
      behavior: 'smooth',
      block: 'nearest' // Only scroll if the element is not in view.
    };
    element.scrollIntoView(scrollOptions);

    // Set up an observer to scroll the selected element into view whenever its dimensions change.
    // This is particularly useful when adding new images, because `element.scrollIntoView()` above is called
    // before the default image loads, i.e. when the image loads, the image element's height increases,
    // causing the image to appear partially out of view.
    const observer = new ResizeObserver(() => {
      element.scrollIntoView(scrollOptions);
    });
    observer.observe(element);
    selectedElementResizeObserverRef.current = observer;
  }, []);

  return (
    <>
      <main className="manage-forms-edit-page-content manage-forms-edit-questions-page-content">
        {renderHeader()}

        {showShareModal && (
          <PublishShareModal
            formName={formState.title}
            formUrl={formUrl}
            createAnnouncementOnPgUrl={createAnnouncementOnPgUrl}
            formConfig={formConfig}
            onUpdateFormConfig={onUpdateFormConfig}
            onUpdateSchedule={onUpdateSchedule}
            onCloseModal={() => setShowShareModal(false)}
            viewOnly={viewOnly}
            isShareableToPG={isShareableToPG}
            formPublishErrors={formPublishErrors}
          />
        )}

        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          height="calc(100% - 78px)" // minus 78px for content header
        >
          <Form
            form={formState}
            formErrors={formErrors}
            selectedElementID={selectedElementID}
            selectedElementRefCallback={selectedElementRefCallback}
            onSelectElement={setSelectedElementID}
            onReorderElement={onReorderElement}
            viewOnly={viewOnly}
          />

          <Editor
            form={formState}
            formErrors={formErrors}
            metadata={metadata}
            onAddMetadata={onAddMetadata}
            metadataValuesWithHeaderUrl={metadataValuesWithHeaderUrl}
            hasAdditionalMetadata={hasAdditionalMetadata}
            viewOnly={viewOnly}
            updateFormImageUrl={updateFormImageUrl}
            selectedElementID={selectedElementID}
            onChangeTitle={onChangeTitle}
            onChangeInstructions={onChangeInstructions}
            onUpdateElement={onUpdateElement}
            onCloneElement={onCloneElement}
            onDeleteElement={onDeleteElement}
            onNewElement={onNewElement}
            template={templateUsageInstructions}
          />
        </Stack>
      </main>

      {shouldShowTooltip() && !isMobileOrTablet && (
        <CustomTooltipAsPopover
          arrow={{
            direction: 'up',
            placement: 'center'
          }}
          popoverProps={{
            id: tooltipId,
            open: tooltipOpen,
            disablePortal: true,
            anchorEl: tooltipAnchorEl,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'center'
            }
          }}
          onClose={onCloseTooltip}
          title="Scheduling options"
          bodyText="Now you can schedule your form to publish or unpublish at a later date."
        >
          <DefaultTooltipButton onButtonClick={onCloseTooltip} />
        </CustomTooltipAsPopover>
      )}
    </>
  );

  function renderHeader() {
    return (
      <header className="manage-forms-edit-page-content-header">
        <Breadcrumbs className="breadcrumbs">
          <Link underline="hover" color="inherit" href={formsUrl}>Home</Link>
          <Typography>Questions</Typography>
        </Breadcrumbs>

        {renderSaveStatus()}
        <Button variant="outlined" href={previewUrl} target="_blank">PREVIEW</Button>
        <Button
          ref={setTooltipAnchorEl}
          variant="contained"
          onClick={() => setShowShareModal(true)}
        >
          PUBLISH & SHARE
        </Button>
      </header>
    );
  }

  function renderSaveStatus() {
    if (viewOnly) return <Alert className="save-status" severity="warning">View Only</Alert>;

    switch (saveStatus) {
      case SaveStatus.Saved: {
        return <Alert className="save-status" severity="success">Saved</Alert>;
      }
      case SaveStatus.Saving: {
        return <Alert className="save-status" severity="info">Saving...</Alert>;
      }
      case SaveStatus.Error: {
        const defaultMsg = 'Please fix error(s) to save';
        return <Alert className="save-status" severity="error">{isEmpty(formErrors.version) ? defaultMsg : formErrors.version}</Alert>;
      }
      default: {
        return null;
      }
    }
  }

  function shouldShowTooltip(): boolean {
    return getLocalStorageItem(TOOLTIP_ID) !== '1';
  }

  function onCloseTooltip(): void {
    setTooltipAnchorEl(null);
    setTooltipAsShown();
  }

  function onChangeTitle(title: string) {
    setFormState(prevState => ({ ...prevState, title }));
  }

  function onChangeInstructions(instructions: string) {
    setFormState(prevState => ({ ...prevState, instructions }));
  }

  function onUpdateElement(element: UnionElement) {
    setFormState(prevState => {
      const newRoot = updateElement(prevState.body, element);
      return { ...prevState, body: newRoot };
    });
  }

  function onCloneElement(element: UnionElement) {
    if (isQuestionType(element.type) && doesNewQuestionExceedLimit()) {
      setIsExceedQuestionsLimitSnackbarOpenCallback();
      return;
    }

    if (element.type === SectionElementType && doesNewSectionExceedLimit(element as SectionElement)) {
      setIsExceedQuestionsLimitSnackbarOpenCallback();
      return;
    }

    setFormState(prevState => {
      const { newRoot, clone } = cloneElement(prevState.body, element);

      if (isNotEmpty(clone)) {
        setSelectedElementID(clone.id);
      }
      return { ...prevState, body: newRoot };
    });
  }

  function onDeleteElement(elementID: string) {
    setFormState(prevState => {
      setSelectedElementID(null);

      const newRoot = deleteElement(prevState.body, elementID);
      return { ...prevState, body: newRoot };
    });
  }

  function onNewElement(elementType: string) {
    if (isQuestionType(elementType) && doesNewQuestionExceedLimit()) {
      setIsExceedQuestionsLimitSnackbarOpenCallback();
      return;
    }

    setFormState(prevState => {
      const root = prevState.body;

      let insertParentElement: UnionElement;
      let insertIndex: number;

      if (isEmpty(selectedElementID)) {
        insertParentElement = root;
        insertIndex = root.children.length; // add to end of root
      } else if (selectedElementID === TitleID || selectedElementID === InstructionsID) {
        insertParentElement = root;

        // if it is a locked template, then any new elements should be added after all the locked questions
        const isLockedTemplate = root.children.some(c => c.locked);

        if (isLockedTemplate) {
          const lastLockedIndex = findLastIndex(root.children, e => e.locked);
          // findLastIndex returns -1 if not found which will result in a valid insertIndex below
          insertIndex = lastLockedIndex + 1;
        } else {
          insertIndex = 0; // add to front of root
        }
      } else {
        /* truth table
        *
        * |                              | parent of selected is a section | selected is a section  | selected is not a section
        * |------------------------------|---------------------------------|------------------------|-------------------------
        * | new element is a section     | add after parent of selected    | add after selected     | add after selected
        * | new element is not a section | add after selected (in section) | add to end of selected | add after selected
        */

        const selectedElement = findElement(root, selectedElementID);
        const selectedElementParent = parentElementOf(root, selectedElementID);

        if (elementType === SectionElementType && selectedElementParent.type === SectionElementType) { // _parent_ of selected element and new element are sections
          insertParentElement = root;
          insertIndex = insertParentElement.children.indexOf(selectedElementParent) + 1; // add after _parent_ of selected element
        } else if (elementType === SectionElementType && selectedElement.type === SectionElementType) { // selected element and new element are sections
          insertParentElement = root;
          insertIndex = insertParentElement.children.indexOf(selectedElement) + 1; // add after selected element
        } else if (selectedElement.type === SectionElementType) { // selected element is a section but new element is _not_ a section
          insertParentElement = selectedElement;
          insertIndex = (selectedElement as SectionElement).children.length; // add to end of selected element
        } else { // selected element is not a section and new element can be anything, including a section
          insertParentElement = selectedElementParent;
          insertIndex = insertParentElement.children.indexOf(selectedElement) + 1; // add after selected element
        }
      }

      const elementToInsert = newElement(elementType);
      setSelectedElementID(elementToInsert.id);

      const newRoot = insertElement(root, insertParentElement.id, insertIndex, elementToInsert);
      return { ...prevState, body: newRoot };
    });
  }

  function onReorderElement(elementID: string, sourceParentID: string, sourceIndex: number, targetParentID: string, targetIndex: number) {
    setFormState(prevState => {
      const newRoot = reorderElement(prevState.body, elementID, sourceParentID, sourceIndex, targetParentID, targetIndex);
      return { ...prevState, body: newRoot };
    });
  }

  function doesNewQuestionExceedLimit() {
    return getNumQuestionsFromRootElement(formState.body) === MAX_QUESTIONS_LIMIT;
  }

  function doesNewSectionExceedLimit(section: SectionElement) {
    const currentQuestionsLength = getNumQuestionsFromRootElement(formState.body);
    const newQuestionsLength = section.children.filter(e => isQuestionType(e.type)).length;

    return currentQuestionsLength + newQuestionsLength > MAX_QUESTIONS_LIMIT;
  }

  function getNumQuestionsFromRootElement(rootElement: RootElement) {
    return flattenRootElement(rootElement).filter(e => isQuestionType(e.type)).length;
  }
}

function setTooltipAsShown(): void {
  setLocalStorageItem(TOOLTIP_ID, '1');
}

import React from 'react';
import AppProvider from 'components/AppProvider';
import EditTopBar, { TabIndex } from '../EditTopBar';
import { AudienceType, PublicAudienceProps, School, StaffAudienceProps, StudentAudienceProps } from '../../../../models/FormProps';
import ContentContainer from './ContentContainer';
import FeedbackFormFab from '../common/FeedbackFormFab';

interface Props {
  username: string;
  logOutUrl: string;
  school: School;
  tabURLs: Array<string>;
  viewableAudience: PublicAudienceProps | StaffAudienceProps | StudentAudienceProps;
  personalisedFieldsTableUrl: string;
  clearPersonalisedFieldsUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  formsUrl: string;
  logUrl: string;
  updateFormUrl: string;
  viewOnly: boolean;
  isHQUser: boolean;
  formHasResponses: boolean;
  version: number;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  getStaffRosterUrl: string;
  enableTeachingGroups: boolean;
  userID: string;
  aeFeedbackFormUrl: string;
  integrationAudience?: AudienceType;
}

export default function Page(props: Props): React.ReactElement {
  const { tabURLs, userID, aeFeedbackFormUrl } = props;

  return (
    <AppProvider>
      <EditTopBar {...props} tab={TabIndex.Audience} setTab={tab => { window.location.href = tabURLs[tab]; }} />
      <ContentContainer {...props} />
      <FeedbackFormFab
        userID={userID}
        aeFeedbackFormUrl={aeFeedbackFormUrl}
        // this page uses an additional sticky footer for navigation
        // to cater for that, shift the fab up slightly
        fabBottomPosition="90px"
      />
    </AppProvider>
  );
}

import React from 'react';
import { Alert } from '@mui/material';
import NoEditMessageSeparator from 'components/form_elements/no_edit_message/NoEditMessageSeparator';

interface NoEditMessageProps {
  isTemplateView: boolean;
  message: string;
}

export default function NoEditMessage(props: NoEditMessageProps): React.ReactElement {
  const { isTemplateView, message } = props;

  if (isTemplateView) {
    return (<Alert severity="info">{message}</Alert>);
  }

  return (<NoEditMessageSeparator message={message} showIcon />);
}

// https://stackoverflow.com/a/14438954
export function arrayUnique(array: Array<any>) {
  return array.filter((value, index) => array.indexOf(value) === index);
}

// findLastIndex requires es2023, so we write this manually
export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: Array<T>) => boolean
): number {
  for (let i = array.length - 1; i >= 0; i--) {
    if (predicate(array[i], i, array)) {
      return i;
    }
  }

  return -1;
}

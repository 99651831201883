import React, { Fragment } from 'react';
import { Box } from '@mui/material';
import NoEditMessage from 'components/form_elements/no_edit_message/NoEditMessage';
import { findLastIndex } from 'components/utils/array';
import { BaseElement, UnionElement, renderElement, RenderElementEditMode, RenderElementAnswerMode, newElement } from 'components/form_elements/BaseElement';
import { Draggable } from 'components/form_elements/Draggable';
import { Selectable } from 'components/form_elements/Selectable';
import { isEmpty } from 'components/utils/equality';
import { isQuestionType } from 'components/form_elements/questions/BaseQuestion';
import { cloneElementInSection, deleteElementInSection, findElementInSection, insertElementInSection, SectionElement, SectionElementType, updateElementInSection } from 'components/form_elements/section/SectionElement';
import { DropZone } from 'components/form_elements/DropZone';
import { ImageElementType } from 'components/form_elements/image/ImageElement';
import NoEditMessageSeparator from 'components/form_elements/no_edit_message/NoEditMessageSeparator';

const START_LOCKED_MESSAGE = 'The questions below cannot be modified .';
const END_LOCKED_MESSAGE = 'This is the end of the locked questions.';

export const RootElementType = 'FormElement::Root';

export interface RootElement extends BaseElement {
  children: Array<UnionElement>;
}

interface RootElementComponentProps {
  element: RootElement;

  editMode?: RenderElementEditMode;
  answerMode?: RenderElementAnswerMode;
  isTemplateView?: boolean;
}

export function RootElementComponent(props: RootElementComponentProps) {
  const { element, editMode, answerMode, isTemplateView } = props;

  const questionIDs = flattenRootElement(element).filter(e => isQuestionType(e.type)).map(e => e.id);
  const sectionIDs = flattenRootElement(element).filter(e => e.type === SectionElementType).map(e => e.id);
  const imageIDs = flattenRootElement(element).filter(e => e.type === ImageElementType).map(e => e.id);

  return (
    <Box className="form-root-element">
      {isEmpty(editMode) ? renderChildrenAnswerMode() : renderChildrenEditMode()}
    </Box>
  );

  function renderChildrenAnswerMode() {
    return (
      <Box>
        {element.children.map((child, index) => (
          <Fragment key={index}>
            {renderElement(child, editMode, answerMode, questionIDs, sectionIDs, imageIDs)}
          </Fragment>
        ))}
      </Box>
    );
  }

  function renderChildrenEditMode() {
    const hasLocked = element.children.some(child => child.locked);

    return (
      <Box>
        {hasLocked && (
          <Box sx={{ mt: '16px', mb: '8px' }}>
            <NoEditMessage isTemplateView={isTemplateView} message={START_LOCKED_MESSAGE} />
          </Box>
        )}
        <DropZone
          parentID={element.id}
          parentElementType={element.type}
          index={0}
          disabled={editMode.viewOnly || hasLocked}
          onDragTo={editMode.onReorderElement}
        />

        {element.children.map((child, index) => {
          const isElementSelected = editMode.selectedElementID === child.id;
          const lastLockedIndex = findLastIndex(element.children, e => e.locked);

          return (
            <Fragment key={index}>
              <Draggable
                className={child.type === SectionElementType ? 'draggable-section' : ''}
                elementID={child.id}
                elementType={child.type}
                parentID={element.id}
                elementIndex={index}
                disabled={editMode.viewOnly || child.locked}
              >
                <Selectable
                  ref={isElementSelected ? editMode.selectedElementRefCallback : null}
                  elementID={child.id}
                  isSelected={isElementSelected}
                  onSelect={editMode.onSelectElement}
                  isLocked={!!child.locked}
                >
                  {renderElement(child, editMode, answerMode, questionIDs, sectionIDs, imageIDs)}
                </Selectable>
              </Draggable>

              <DropZone
                parentID={element.id}
                parentElementType={element.type}
                index={index + 1}
                disabled={
                  editMode.viewOnly
                  // enable dropzone after last locked child to allow reordering to before the first unlocked child
                  || (child.locked && index < lastLockedIndex)
                }
                onDragTo={editMode.onReorderElement}
              />
              {!isTemplateView && index === lastLockedIndex && (
                <Box sx={{ mt: '8px', mb: '16px' }}>
                  <NoEditMessageSeparator message={END_LOCKED_MESSAGE} />
                </Box>
              )}
            </Fragment>
          );
        })}
      </Box>
    );
  }
}

export function flattenRootElement(root: RootElement) {
  return root.children.flatMap(child => {
    switch (child.type) {
      case SectionElementType:
        return [child, ...(child as SectionElement).children];
      default:
        return child;
    }
  }); // flatten the nested array from Section element
}

export function findElement(root: RootElement, elementID: string) {
  for (let child of root.children) {
    if (child.id === elementID) return child;

    if (child.type === SectionElementType) {
      const section = (child as SectionElement);
      const element = findElementInSection(section, elementID);
      if (element !== null) return element;
    }
  }

  return null;
}

export function parentElementOf(root: RootElement, elementID: string) {
  for (let child of root.children) {
    if (child.id === elementID) return root;

    if (child.type === SectionElementType) {
      const section = (child as SectionElement);
      const element = findElementInSection(section, elementID);
      if (element !== null) return section;
    }
  }

  return null;
}

export function insertElement(root: RootElement, parentElementID: string, index: number, element: UnionElement): RootElement {
  const children = [...root.children];

  if (parentElementID === root.id) {
    children.splice(index, 0, element);
  } else {
    for (let i = 0; i < children.length; i++) {
      if (children[i].id !== parentElementID) continue;

      if (children[i].type === SectionElementType) {
        let section = (children[i] as SectionElement);
        section = insertElementInSection(section, index, element);
        children[i] = section;
      }

      break;
    }
  }

  return { ...root, children };
}

export function updateElement(root: RootElement, element: UnionElement): RootElement {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      children[i] = element;
      break;
    }

    if (children[i].type === SectionElementType) {
      let section = (children[i] as SectionElement);

      if (findElementInSection(section, element.id) !== null) {
        section = updateElementInSection(section, element);
        children[i] = section;
      }
    }
  }

  return { ...root, children };
}

export function cloneElement(root: RootElement, element: UnionElement) : {
  newRoot: RootElement;
  clone: UnionElement;
} {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === element.id) {
      const clone = newElement(element.type, element);
      const newRoot = insertElement(root, root.id, i + 1, clone);
      return { newRoot, clone };
    }

    if (children[i].type === SectionElementType) {
      const section = (children[i] as SectionElement);

      if (findElementInSection(section, element.id) !== null) {
        const { newSection, clone } = cloneElementInSection(section, element);
        children[i] = newSection;
        return { newRoot: { ...root, children }, clone };
      }
    }
  }

  return { newRoot: { ...root, children }, clone: null };
}

export function deleteElement(root: RootElement, elementID: string): RootElement {
  const children = [...root.children];

  for (let i = 0; i < children.length; i++) {
    if (children[i].id === elementID) {
      children.splice(i, 1);
      break;
    }

    if (children[i].type === SectionElementType) {
      let section = (children[i] as SectionElement);

      if (findElementInSection(section, elementID) !== null) {
        section = deleteElementInSection(section, elementID);
        children[i] = section;
      }
    }
  }

  return { ...root, children };
}

export function reorderElement(root: RootElement, elementID: string, sourceParentID: string, sourceIndex: number, targetParentID: string, targetIndex: number) {
  const element = findElement(root, elementID);

  if (sourceIndex >= targetIndex || sourceParentID !== targetParentID) {
    return insertElement(deleteElement(root, elementID), targetParentID, targetIndex, element);
  } else {
    // assumes deleteElement only removes the first instance of matching elementID
    return deleteElement(insertElement(root, targetParentID, targetIndex, element), elementID);
  }
}

import React, { useRef, useState } from 'react';
import usePromptWindowUnload, { removePromptWindowUnload } from '../../../../utils/prompt_window_unload';
import { FormErrors, School, AudienceProps, AudienceType } from '../../../../models/FormProps';
import Content from './Content';
import { useDidUpdateEffect } from '../CustomHooks';
import { getCsrfToken } from '../../../../utils/csrf';
import { addTask } from '../../../../utils/add_task';
import useServerLogger from '../../../../utils/use_server_logger';

export enum SaveStatus {
  Saved = 0,
  Saving,
  Error
}

interface Props {
  school: School;
  tabURLs: Array<string>;
  viewableAudience: AudienceProps;
  personalisedFieldsTableUrl: string;
  clearPersonalisedFieldsUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  formsUrl: string;
  logUrl: string;
  updateFormUrl: string;
  viewOnly: boolean;
  isHQUser: boolean;
  formHasResponses: boolean;
  version: number;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  getStaffRosterUrl: string;
  enableTeachingGroups: boolean;
  integrationAudience?: AudienceType;
}

export default function ContentContainer(props: Props): React.ReactElement {
  const {
    school,
    tabURLs,
    viewableAudience,
    personalisedFieldsTableUrl,
    clearPersonalisedFieldsUrl,
    downloadPersonalisedFieldsUrl,
    uploadPersonalisedFieldsUrl,
    formsUrl,
    logUrl,
    viewOnly,
    isHQUser,
    formHasResponses,
    version,
    getSchoolsUrl,
    getStudentRosterUrl,
    getStaffRosterUrl,
    updateFormUrl,
    enableTeachingGroups,
    integrationAudience
  } = props;

  const saveTimerRef = useRef<number>();

  const [audienceState, setAudienceState] = useState(viewableAudience);

  const formVersion = useRef<number>(version);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [saveStatus, setSaveStatus] = useState(SaveStatus.Saved);

  const sendLog = useServerLogger(logUrl);

  if (!viewOnly) useDidUpdateEffect(saveAudienceWithDelay, [audienceState]);

  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  return (
    <Content
      school={school}
      tabURLs={tabURLs}
      viewableAudience={audienceState}
      saveStatus={saveStatus}
      setSaveStatusCallback={setSaveStatus}
      changeAudienceCallback={changeAudienceCallback}
      personalisedFieldsTableUrl={personalisedFieldsTableUrl}
      clearPersonalisedFieldsUrl={clearPersonalisedFieldsUrl}
      downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
      uploadPersonalisedFieldsUrl={uploadPersonalisedFieldsUrl}
      formsUrl={formsUrl}
      viewOnly={viewOnly}
      getSchoolsUrl={getSchoolsUrl}
      getStudentRosterUrl={getStudentRosterUrl}
      getStaffRosterUrl={getStaffRosterUrl}
      isHQUser={isHQUser}
      formHasResponses={formHasResponses}
      formErrors={formErrors}
      enableTeachingGroups={enableTeachingGroups}
      sendLog={sendLog}
      integrationAudience={integrationAudience}
    />
  );

  function changeAudienceCallback(updatedAudience: AudienceProps) {
    setAudienceState(updatedAudience);
  }

  function saveAudienceWithDelay() {
    setSaveStatus(SaveStatus.Saving);
    clearTimeout(saveTimerRef.current);
    saveTimerRef.current = window.setTimeout(saveAudience, 1000);

    return function cleanup() {
      clearTimeout(saveTimerRef.current);
    };
  }

  function saveAudience() {
    return addTask(updateFormTask);
  }

  function updateFormTask() {
    setSaveStatus(SaveStatus.Saving);
    const options = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': getCsrfToken() },
      body: JSON.stringify({
        form: {
          audience: audienceState,
          lock_version: formVersion.current
        }
      })
    };

    return fetch(updateFormUrl, options)
      .then(response => {
        if (response.redirected) {
          removePromptWindowUnload();
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setFormErrors(data.errors);
          setSaveStatus(data.success ? SaveStatus.Saved : SaveStatus.Error);
          if (data.success) formVersion.current = data.version;
        }
      })
      .catch(e => {
        console.log(e);
        setSaveStatus(SaveStatus.Error);
      });
  }
}

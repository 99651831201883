import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Theme, useMediaQuery } from '@mui/material';
import { AudienceProps, AudienceType, newPublicAudienceProps, newUntargetedStaffAudienceProps, newUntargetedStudentAudienceProps, PublicAudienceProps, StaffAudienceProps, StudentAudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../models/FormProps';
import PublicAudienceTypeImage from '../../../../../../assets/images/audience_types/public-audience.svg';
import StudentAudienceTypeImage from '../../../../../../assets/images/audience_types/student-audience.svg';
import StaffAudienceTypeImage from '../../../../../../assets/images/audience_types/staff-audience.svg';
import AudienceGuide from './common/AudienceGuide';

interface Props {
  viewableAudience: AudienceProps;
  viewOnly: boolean;
  updateViewableAudienceCallback: (viewableAudience: PublicAudienceProps | StaffAudienceProps
  | StudentAudienceProps | UntargetedStaffAudienceProps | UntargetedStudentAudienceProps) => void;
  formHasResponses: boolean;
  expectedAudience?: AudienceType;
}

export default function AudienceTypeSelector(props: Props): React.ReactElement {
  const { viewableAudience, viewOnly, updateViewableAudienceCallback, formHasResponses, expectedAudience } = props;
  const audienceType = viewableAudience.type;

  const isUnrestrictedAudience = !expectedAudience;

  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return (
    <Stack spacing={5}>
      <Typography variant="h5" textAlign="center" fontWeight="bold" color="primary.main">
        Select your audience type
      </Typography>
      <Stack direction={isMobileOrTablet ? 'column' : 'row'} justifyContent="center" alignItems="center" spacing={3}>
        {(isUnrestrictedAudience || [AudienceType.PUBLIC].includes(expectedAudience)) && (
          <Button
            className={audienceType === AudienceType.PUBLIC ? 'audience-editor-button-selected' : 'audience-editor-button'}
            variant="outlined"
            onClick={() => selectAudienceTypeCallback(AudienceType.PUBLIC)}
            disabled={viewOnly}
          >
            <img alt="All" src={PublicAudienceTypeImage} className="audience-editor-button-image" />
          </Button>
        )}
        {(isUnrestrictedAudience || [AudienceType.STUDENT, AudienceType.UNTARGETED_STUDENT].includes(expectedAudience)) && (
          <Button
            className={audienceType === AudienceType.STUDENT || audienceType === AudienceType.UNTARGETED_STUDENT ? 'audience-editor-button-selected' : 'audience-editor-button'}
            variant="outlined"
            onClick={() => selectAudienceTypeCallback(AudienceType.UNTARGETED_STUDENT)}
            disabled={viewOnly}
          >
            <img alt="Students" src={StudentAudienceTypeImage} className="audience-editor-button-image" />
          </Button>
        )}
        {(isUnrestrictedAudience || [AudienceType.STAFF, AudienceType.UNTARGETED_STAFF].includes(expectedAudience)) && (
          <Button
            className={audienceType === AudienceType.STAFF || audienceType === AudienceType.UNTARGETED_STAFF ? 'audience-editor-button-selected' : 'audience-editor-button'}
            variant="outlined"
            onClick={() => selectAudienceTypeCallback(AudienceType.UNTARGETED_STAFF)}
            disabled={viewOnly}
          >
            <img alt="Staff" src={StaffAudienceTypeImage} className="audience-editor-button-image" />
          </Button>
        )}
      </Stack>
      <AudienceGuide />
    </Stack>
  );

  function selectAudienceTypeCallback(type: AudienceType) {
    if (audienceType === type && audienceType === AudienceType.PUBLIC) return;

    if (isSameAudienceType(audienceType, type)) return;

    switch (audienceType) {
      case AudienceType.PUBLIC: {
        if (formHasResponses && !confirm('This will restrict the form audience to staff/students only. You will no longer be able to view any collected responses. Click to proceed.')) return;
        break;
      }
      default: {
        if (!confirm('This will delete all currently selected groups and individuals. Click to proceed.')) return;
      }
    }

    switch (type) {
      case AudienceType.PUBLIC:
        updateViewableAudienceCallback(newPublicAudienceProps());
        break;
      case AudienceType.UNTARGETED_STAFF:
        updateViewableAudienceCallback(newUntargetedStaffAudienceProps());
        break;
      case AudienceType.UNTARGETED_STUDENT:
        updateViewableAudienceCallback(newUntargetedStudentAudienceProps());
        break;
      default:
        // Do nothing
    }
  }

  // Return true as long as the selected type is similar to the current audience type.
  function isSameAudienceType(currentType: AudienceType, selectedType: AudienceType) {
    if (currentType === AudienceType.STUDENT || currentType === AudienceType.UNTARGETED_STUDENT) {
      return selectedType === AudienceType.STUDENT || selectedType === AudienceType.UNTARGETED_STUDENT;
    }

    if (currentType === AudienceType.STAFF || currentType === AudienceType.UNTARGETED_STAFF) {
      return selectedType === AudienceType.STAFF || selectedType === AudienceType.UNTARGETED_STAFF;
    }

    return false;
  }
}

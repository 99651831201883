import ErrorOutline from '@mui/icons-material/ErrorOutline';
import { SxProps } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { red, teal, grey } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { FormConfigProps, FormUpdateResponseData, PublishStatusProps, UpdateScheduleProps, UpdateScheduleResponse } from 'components/models/FormProps';
import { FormStateActions } from 'components/utils/form_state';
import React, { useState } from 'react';
import CustomDialog from 'components/pages/common/CustomDialog';
import Disclaimer from './Disclaimer';
import SetSchedule from './SetSchedule';
import { commonStyles } from './styles';

interface ConfigurePublishProps {
  formConfig: FormConfigProps;
  onUpdateFormConfig: (data: FormConfigProps) => Promise<FormUpdateResponseData | null>;
  onUpdateSchedule: (data: UpdateScheduleProps) => Promise<UpdateScheduleResponse | null>;
  viewOnly: boolean;
  formPublishErrors: Array<string>;
}

const DEFAULT_ERROR_MESSAGE = 'Something went wrong while updating the form. Please refresh the page and try again.';

export default function ConfigurePublish(props: ConfigurePublishProps): React.ReactElement {
  const { formConfig, viewOnly, formPublishErrors } = props;

  const isPublished = formConfig.state === PublishStatusProps.Published;

  const [confirmPublishModal, setConfirmPublishModal] = useState<boolean>(false);
  const [confirmUnpublishModal, setConfirmUnpublishModal] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<{ switch?: string, checkbox?: string, pickers?: string } | null>(null);

  return (
    <>
      <Box>
        <Typography variant="h5" fontSize="24px" fontWeight="700">Publish</Typography>
      </Box>
      {formPublishErrors.length > 0 ? renderWithErrorDisplay() : renderNoErrorDisplay()}
    </>
  );

  function onChangePublishStatusSwitch(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (checked) {
      if (formConfig.state !== PublishStatusProps.Closed && formConfig.schedule.publish_datetime) {
        setConfirmPublishModal(true);
        return;
      }

      onChangePublishStatus(FormStateActions.MANUAL_PUBLISH);
    }

    if (!checked) {
      if (formConfig.schedule.unpublish_datetime) {
        setConfirmUnpublishModal(true);
        return;
      }

      onChangePublishStatus(FormStateActions.MANUAL_UNPUBLISH);
    }
  }

  function onChangePublishStatus(action: FormStateActions.MANUAL_PUBLISH | FormStateActions.MANUAL_UNPUBLISH) {
    setErrorMessage(null);

    props.onUpdateSchedule({ action })
      .then(response => {
        if (!response || response.success) {
          setErrorMessage(null);
          return;
        }

        // TODO: see if use `response.success`'s value to do type narrowing
        // this is coded this way because the type narrowing didn't work
        if ('errors' in response && 'version' in response.errors) {
          setErrorMessage({ switch: response.errors.version });
        } else {
          setErrorMessage({ switch: DEFAULT_ERROR_MESSAGE });
        }
      })
      .catch(() => {
        setErrorMessage({ switch: DEFAULT_ERROR_MESSAGE });
      })
      .finally(() => {
        setConfirmPublishModal(false);
        setConfirmUnpublishModal(false);
      });
  }

  function onChangeEnableEmailCopyOfResponseCheckbox(event: React.ChangeEvent<HTMLInputElement>) {
    props.onUpdateFormConfig({
      ...formConfig,
      enable_email_copy_of_response: event.target.checked
    })
      .then(response => {
        if (response.success) {
          setErrorMessage(null);
          return;
        }

        if (response.errors?.version) {
          setErrorMessage({ checkbox: response.errors.version });
        } else {
          setErrorMessage({ checkbox: DEFAULT_ERROR_MESSAGE });
        }
      })
      .catch(() => {
        setErrorMessage({ checkbox: DEFAULT_ERROR_MESSAGE });
      });
  }

  function onUpdateSchedule(data: UpdateScheduleProps): Promise<UpdateScheduleResponse | null> {
    return props.onUpdateSchedule(data)
      .then(response => {
        if (response.success) {
          setErrorMessage(null);
          return response;
        }

        if ('errors' in response && 'version' in response.errors) {
          setErrorMessage({ pickers: response.errors.version });
        } else {
          setErrorMessage({ pickers: DEFAULT_ERROR_MESSAGE });
        }

        return response;
      })
      .catch(() => {
        setErrorMessage({ pickers: DEFAULT_ERROR_MESSAGE });
        return null;
      });
  }

  function renderWithErrorDisplay() {
    return (
      <FormGroup>
        <FormControlLabel
          control={(
            <Switch
              checked={isPublished}
              disabled
            />
          )}
          label={(
            <Typography variant="body1" component="span" color="#9E9E9E">
              Form is{' '}
              <Typography
                variant="body1"
                component="span"
                fontWeight="bold"
                color="textDisabled"
              >
                {isPublished ? 'OPEN' : 'CLOSED'}
              </Typography>
            </Typography>
          )}
          labelPlacement="start"
          slotProps={{ typography: { typography: '16px' } }}
          sx={getPublishBoxStyles(isPublished, true)}
        />
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, paddingTop: 0.5, height: '24px', color: red[900] }}>
          <ErrorOutline fontSize="medium" />
          <Typography variant="body2">{formPublishErrors.join('\n')}</Typography>
        </Box>
      </FormGroup>
    );
  }

  function renderNoErrorDisplay() {
    return (
      <>
        <Disclaimer />

        <Box>
          <Typography sx={{ typography: 'body1', fontWeight: 'bold', pb: 1 }}>
            Form status
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={(
                <Switch
                  checked={isPublished}
                  onChange={onChangePublishStatusSwitch}
                  disabled={viewOnly || !!errorMessage}
                />
              )}
              label={(
                <Typography variant="body1" component="span">
                  Form is{' '}
                  <Typography
                    variant="body1"
                    component="span"
                    fontWeight="bold"
                  >
                    {isPublished ? 'OPEN' : 'CLOSED'}
                  </Typography>
                </Typography>
              )}
              labelPlacement="start"
              slotProps={{ typography: { typography: '16px' } }}
              sx={getPublishBoxStyles(isPublished)}
            />
          </FormGroup>

          {errorMessage?.switch && (
            <Alert sx={commonStyles} severity="error">
              {errorMessage.switch}
            </Alert>
          )}
        </Box>

        <Box>
          <Typography sx={{ typography: 'body1', fontWeight: 'bold' }}>
            Scheduling options
          </Typography>
          <Typography sx={{ typography: 'body1', pb: 1 }}>
            Set your form to publish and/or unpublish on your chosen date and time
          </Typography>
          <FormGroup sx={{ pl: 2, gap: 1 }}>
            <SetSchedule
              key={String(isPublished)}
              disabled={!!errorMessage}
              publishStatus={formConfig.state}
              initialSchedule={formConfig.schedule}
              viewOnly={viewOnly}
              onUpdateSchedule={onUpdateSchedule}
            />
          </FormGroup>
        </Box>

        <Divider />

        <Box>
          <Typography sx={{ typography: 'body1', fontWeight: 'bold' }}>Response receipts</Typography>
          <FormGroup sx={{ pl: 2 }}>
            <FormControlLabel
              sx={{ pointerEvents: 'none' }}
              control={(
                <Checkbox
                  sx={{ pointerEvents: 'auto' }}
                  defaultChecked={formConfig.enable_email_copy_of_response}
                  onChange={onChangeEnableEmailCopyOfResponseCheckbox}
                  disabled={viewOnly || !!errorMessage}
                />
              )}
              label="Respondents can receive a copy of their submission by email"
              componentsProps={{ typography: { typography: '16px' } }}
            />
          </FormGroup>

          {errorMessage?.checkbox && (
            <Alert sx={commonStyles} severity="error">
              {errorMessage.checkbox}
            </Alert>
          )}
        </Box>

        <CustomDialog
          open={confirmPublishModal}
          handleClose={() => setConfirmPublishModal(false)}
          onConfirm={() => onChangePublishStatus(FormStateActions.MANUAL_PUBLISH)}
          title="Publish now?"
          confirmText="Publish now"
          message="This will overwrite your scheduled publish date and time."
        />

        <CustomDialog
          open={confirmUnpublishModal}
          handleClose={() => setConfirmUnpublishModal(false)}
          onConfirm={() => onChangePublishStatus(FormStateActions.MANUAL_UNPUBLISH)}
          title="Unpublish now?"
          confirmText="Unpublish now"
          message="This will overwrite your scheduled unpublish date and time."
        />
      </>
    );
  }
}

function getPublishBoxStyles(isPublished: boolean, isDisabled = false): SxProps {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    py: 1,
    px: 2,
    m: 0,
    borderRadius: '4px',
    // TODO: may be good to shift this into the central theme
    // may need to augment the theme's typings as well to support
    // custom colors
    // eslint-disable-next-line no-nested-ternary
    bgcolor: isPublished ? teal[50] : isDisabled ? grey[200] : red[50]
  };
}

import { DefaultTooltipButtonProps, PersonalisedFieldTooltipProps } from 'components/pages/common/CustomTooltipAsPopover';
import { LOCALSTORAGE_KEYS, setLocalStorageItem } from 'components/utils/local_storage';

const formMenuElementId = 'form-menu';
const templateButtonElementId = 'template-button';

type TooltipProps = Omit<PersonalisedFieldTooltipProps, 'popoverProps' | 'children'> & {
  popoverProps: Omit<PersonalisedFieldTooltipProps['popoverProps'], 'id'> & { id: string }
} & DefaultTooltipButtonProps;

const tooltipPropsArrForMobile: Array<TooltipProps> = [{
  popoverProps: {
    id: LOCALSTORAGE_KEYS.EDIT_AUDIENCE_FORM_MENU_TOOLTIP,
    open: false,
    anchorEl: (): HTMLElement | null => document.getElementById(formMenuElementId),
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'right'
    }
  },
  arrow: {
    direction: 'down',
    placement: 'right'
  },
  title: 'Quick form actions',
  bodyText: "We've added “Copy form link” and moved “Publish/Unpublish form” here.",
  onClose: (): void => { setLocalStorageItem(LOCALSTORAGE_KEYS.EDIT_AUDIENCE_FORM_MENU_TOOLTIP, '1'); },
  buttonText: 'GOT IT'
},
{
  popoverProps: {
    id: LOCALSTORAGE_KEYS.ALL_TEMPLATES_BUTTON_TOOLTIP,
    open: false,
    anchorEl: (): HTMLElement | null => document.getElementById(templateButtonElementId),
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    }
  },
  arrow: {
    direction: 'down',
    placement: 'left'
  },
  title: 'New templates added!',
  bodyText: 'We have added common templates for your usage in the template library.',
  onClose: (): void => { setLocalStorageItem(LOCALSTORAGE_KEYS.ALL_TEMPLATES_BUTTON_TOOLTIP, '1'); },
  onButtonClick: (): void => { window.location.href = '/manage/form_templates'; },
  buttonText: 'SHOW ME'
}];

const tooltipPropsArrForDesktop: Array<TooltipProps> = [{
  popoverProps: {
    id: LOCALSTORAGE_KEYS.EDIT_AUDIENCE_FORM_MENU_TOOLTIP,
    open: false,
    anchorEl: (): HTMLElement | null => document.getElementById(formMenuElementId),
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right'
    }
  },
  arrow: {
    direction: 'up',
    placement: 'right'
  },
  title: 'Quick form actions',
  bodyText: "We've added “Copy form link” and moved “Publish/Unpublish form” here.",
  onClose: (): void => { setLocalStorageItem(LOCALSTORAGE_KEYS.EDIT_AUDIENCE_FORM_MENU_TOOLTIP, '1'); },
  buttonText: 'GOT IT'
},
{
  popoverProps: {
    id: LOCALSTORAGE_KEYS.ALL_TEMPLATES_BUTTON_TOOLTIP,
    open: false,
    anchorEl: (): HTMLElement | null => document.getElementById(templateButtonElementId),
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center'
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right'
    }
  },
  arrow: {
    direction: 'up',
    placement: 'right'
  },
  title: 'New templates added!',
  bodyText: 'We have added common templates for your usage in the template library.',
  onClose: (): void => { setLocalStorageItem(LOCALSTORAGE_KEYS.ALL_TEMPLATES_BUTTON_TOOLTIP, '1'); },
  onButtonClick: (): void => { window.location.href = '/manage/form_templates'; },
  buttonText: 'SHOW ME'
}];

export default function getTooltipPropsArr(isMobile: boolean): Array<TooltipProps> {
  return isMobile ? tooltipPropsArrForMobile : tooltipPropsArrForDesktop;
}

export { formMenuElementId, templateButtonElementId };

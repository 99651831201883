import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, Button, useMediaQuery, ButtonProps } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ThemeProvider, useTheme, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import SnaTheme from 'components/pages/sna/SnaTheme';
import React, { useContext, useEffect, useState } from 'react';
import { getLocalStorageItem, LOCALSTORAGE_KEYS } from 'components/utils/local_storage';
import CustomTooltipAsPopover, { DefaultTooltipButton, type PersonalisedFieldTooltipProps } from 'components/pages/common/CustomTooltipAsPopover';
import { FeaturesModalContext } from 'components/FeaturesModalProvider';
import SunflowerImage from '../../../../../../assets/images/sna/sunflowers/sunflower.svg';
import { FormTemplatePreview } from '../../../../models/FormTemplate';
import FormsSection from './FormsSection';
import { FormData } from './forms_table/FormsTable';
import FormTemplateCard from './FormTemplateCard';
import { HomeLayoutPadding } from './HomeLayout';
import getTooltipPropsArr, { templateButtonElementId } from './tooltipPropsArray';

export interface ContentProps {
  createNewFormUrl: string;
  enableSna: boolean;
  formsData: Array<FormData>;
  formTemplates: Array<FormTemplatePreview>;
  formTemplatesUrl: string;
  snaUrl: string;
}

const FORM_TEMPLATE_CARD_WIDTH = 223;

const TemplateLibraryButton = ({ formTemplatesUrl, size }: {
  formTemplatesUrl: string;
  size: ButtonProps['size']
}) => (
  <Button
    id={templateButtonElementId}
    size={size}
    component="a"
    href={formTemplatesUrl}
    endIcon={<KeyboardArrowRightIcon />}
  >
    All templates
  </Button>
);

export default function Content(props: ContentProps): React.ReactElement {
  const { createNewFormUrl, enableSna, formsData, formTemplates, formTemplatesUrl, snaUrl } = props;
  const { anyPopupOpen } = useContext(FeaturesModalContext);
  const [tooltipPropsArray, setTooltipPropsArray] = useState<Array<PersonalisedFieldTooltipProps>>([]);
  const [openTooltipIndex, setOpenTooltipIndex] = useState(0);

  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  useEffect(() => {
    const pageTooltipPropsArr = getTooltipPropsArr(isMobileOrTablet);

    // We compute tooltipPropsArray within useEffect because we need the render to be complete before
    // we can retrieve the reference to the anchor elements.
    const propsForTooltipsToBeShown = pageTooltipPropsArr.filter(tooltipProps => getLocalStorageItem(tooltipProps.popoverProps.id as LOCALSTORAGE_KEYS) !== '1');
    const isMulti = propsForTooltipsToBeShown.length > 1;

    setTooltipPropsArray(propsForTooltipsToBeShown.map((tooltipProps, i) => {
      const { onClose, onButtonClick, buttonText } = tooltipProps;
      const isLastElement = i === propsForTooltipsToBeShown.length - 1;

      // If user intention is to close, we'll dismiss remaining tooltips as well.
      // Otherwise, we will advance the index to display the next tooltip if applicable.
      const onCloseTooltip = () => { onClose(); setOpenTooltipIndex(-1); };
      const onTooltipButtonClick = () => { onButtonClick && onButtonClick(); onClose(); setOpenTooltipIndex(i + 1); };

      const newButtonText = isMulti && !isLastElement ? 'NEXT' : buttonText;

      // We pass an empty object to DefaultTooltipButton for sx props to overwrite the default value for sx.
      const children = propsForTooltipsToBeShown.length === 1 ? <DefaultTooltipButton onButtonClick={onTooltipButtonClick} buttonText={newButtonText} />
        : (
          <Stack direction="row" alignContent="center" justifyContent="space-between">
            <Typography variant="button" sx={{ margin: 'auto 0' }}>{`${i + 1}/${propsForTooltipsToBeShown.length}`}</Typography>
            <DefaultTooltipButton onButtonClick={onTooltipButtonClick} buttonText={newButtonText} sx={{}} />
          </Stack>
        );

      return {
        ...tooltipProps,
        onClose: onCloseTooltip,
        children
      };
    }));
  }, [isMobileOrTablet]);

  return (
    <Stack spacing={4}>
      {renderFormTemplates()}
      {renderForms()}
      {enableSna && renderAdditionalTools()}
      {!anyPopupOpen && tooltipPropsArray.length > 0 && (
        tooltipPropsArray.map(({ popoverProps, ...tooltipProps }, index) => (
          <CustomTooltipAsPopover key={tooltipProps.title} {...tooltipProps} popoverProps={{ ...popoverProps, open: index === openTooltipIndex }} />
        ))
      )}
    </Stack>
  );

  function renderFormTemplates() {
    return (
      <Stack spacing={1} alignItems="start">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h5" color="primary" fontWeight="bold">
              Featured Templates
            </Typography>
          </Grid>
          {!isMobileOrTablet && (
            <Grid item>
              <TemplateLibraryButton size="large" formTemplatesUrl={formTemplatesUrl} />
            </Grid>
          )}
        </Grid>

        <Box
          sx={{
            position: 'relative',
            // "Remove" padding added by the home page container so that this element can extend to the edge of the screen when scrolled.
            left: { xs: theme.spacing(-HomeLayoutPadding.xs), lg: 0 },
            // Expand this element to fill the width of the screen.
            width: { xs: `calc(100% + ${theme.spacing(HomeLayoutPadding.xs * 2)})`, lg: '100%' }
          }}
        >
          <Box
            display="grid"
            gridTemplateColumns={`repeat(auto-fit, minmax(${FORM_TEMPLATE_CARD_WIDTH}px, 1fr))`}
            gridAutoFlow="column"
            gridAutoColumns={`minmax(${FORM_TEMPLATE_CARD_WIDTH}px, 1fr)`}
            columnGap={theme.spacing(2)}
            overflow="auto"
            paddingX={{ xs: theme.spacing(HomeLayoutPadding.xs), lg: 0 }}
            paddingY={1}
          >
            {formTemplates.map(template => (
              <FormTemplateCard key={template.id} template={template} />
            ))}
          </Box>
        </Box>

        {isMobileOrTablet && (
          <TemplateLibraryButton size="small" formTemplatesUrl={formTemplatesUrl} />
        )}
      </Stack>
    );
  }

  function renderForms() {
    return (
      <FormsSection formsData={formsData} createNewFormUrl={createNewFormUrl} />
    );
  }

  function renderAdditionalTools() {
    return (
      <Stack spacing={2}>
        <Typography variant="h5" color="primary" fontWeight="bold">
          Additional Tools
        </Typography>

        <ThemeProvider theme={SnaTheme}>
          <Card className="sna-card" justify-content="space-around">
            <CardActionArea className="sna-card-action-area" component="a" href={snaUrl}>

              <CardContent className="sna-card-header">
                <Typography variant="tableHeader" fontWeight="600">Create Sociogram</Typography>
                <Typography position="relative" bottom="4px" fontWeight="800" fontSize="10px" lineHeight="14px" color="#33658A">BETA</Typography>
                <img width="95px" style={{ position: 'absolute', right: '-5px', top: '5px' }} alt="" src={SunflowerImage} />
              </CardContent>
              <CardContent className="sna-card-description">
                <Typography variant="landingDescription">Understand students&apos; friendships in your form class</Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </ThemeProvider>
      </Stack>
    );
  }
}

import React, { useState, useEffect, useRef } from 'react';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

import { isEmpty } from 'components/utils/equality';
import {
  Clazz,
  isEntityEmpty,
  Level,
  newStudentAudienceProps,
  School,
  Student,
  StudentAudienceProps,
  TeachingGroup
} from 'components/models/FormProps';
import NoRosterContent from '../../NoRosterContent';
import StudentAudienceSelector from './StudentAudienceSelector';
import SelectedStudentAudience from './SelectedStudentAudience';

interface Props {
  school: School;
  viewableAudience: StudentAudienceProps;
  updateViewableAudienceCallback: (viewableAudience: StudentAudienceProps) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  rosterUrl: string;
}

export default function StudentSelectorContent(props: Props): React.ReactElement {
  const { viewableAudience, school, isHQUser, viewOnly, rosterUrl, updateViewableAudienceCallback } = props;
  const [schools, setSchools] = useState<Array<School>>([]);

  // Under the StudentAudienceSelector component, there is a child component
  // that renders the search results. The search results component needs this
  // scroll container ref, so this ref is drilled down to it.
  const scrollContainerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (isHQUser) getSchools();
  }, []);

  return (isEntityEmpty(school) && !isHQUser) ? <NoRosterContent /> : renderContent();

  function renderContent() {
    if (isEntityEmpty(school) && isEmpty(schools)) {
      return <CircularProgress className="circular" />;
    }

    return (
      <Grid container spacing={3}>
        <Grid ref={scrollContainerRef} item xs={5} className="audience-editor-audience-selector-container">
          <StudentAudienceSelector
            scrollContainerRef={scrollContainerRef}
            school={school}
            schools={schools}
            viewableAudience={viewableAudience}
            addViewableEntityCallback={addViewableEntityCallback}
            changeAcademicYearCallback={changeAcademicYearCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            rosterUrl={rosterUrl}
          />
        </Grid>
        <Grid item xs={7} className="audience-editor-audience-selector-container">
          <SelectedStudentAudience
            schools={schools}
            viewableAudience={viewableAudience}
            removeViewableEntityCallback={removeViewableEntityCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
          />
        </Grid>
      </Grid>
    );
  }

  function addViewableEntityCallback(viewableEntity: School | Level | Clazz | Student | TeachingGroup) {
    const found = viewableAudience.entities.find(entity => entity.entity_id === viewableEntity.entity_id && entity.entity_type === viewableEntity.entity_type);
    if (isEntityEmpty(found)) {
      const entities = [...viewableAudience.entities, viewableEntity];
      updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
    }
  }

  function changeAcademicYearCallback(year: number) {
    updateViewableAudienceCallback(newStudentAudienceProps({ year }));
  }

  function removeViewableEntityCallback(viewableEntity: School | Level | Clazz | Student | TeachingGroup) {
    const entities = [...viewableAudience.entities].filter(entity => entity.entity_id !== viewableEntity.entity_id || entity.entity_type !== viewableEntity.entity_type);

    updateViewableAudienceCallback(newStudentAudienceProps({ entities, year: viewableAudience.year }));
  }

  function getSchools() {
    const requestOptions = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return fetch(rosterUrl, requestOptions)
      .then(response => response.json())
      .then(data => {
        if (data) {
          setSchools(data.schools);
        }
      });
  }
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import SnaTheme from 'components/pages/sna/SnaTheme';
import { List, ListItem } from '@mui/material';
import FeatureIntroDialog, { FeatureKey } from '../../common/FeatureIntroDialog';
import HeroImage from '../../../../../../../assets/images/sna/marketing/tg-intro.svg';

interface Props {
  userID: string;
  snaUrl: string;
}

export default function ConnectogramTGModal(props: Props): React.ReactElement {
  const { userID, snaUrl } = props;

  return (
    <ThemeProvider theme={SnaTheme}>
      <FeatureIntroDialog userID={userID} featureKey={FeatureKey.ConnectogramTG}>
        {({ onDialogClose }) => (
          <Stack direction={{ xs: 'column', md: 'row' }} justifyContent="space-between" className="features-modal-dialog-container">
            <Stack justifyContent="center" alignItems="center" minWidth={{ xs: 400, md: 600 }} bgcolor="#FFF3CD" pt={3} pb={6} spacing={3}>
              <Typography variant="h3" fontSize="20px" lineHeight="27px" maxWidth={{ xs: 440 }}>
                Teaching Groups now supported in Connecto-gram!
              </Typography>
              <Box component="img" src={HeroImage} maxWidth={{ xs: 440 }} />
            </Stack>

            <Stack spacing={3} py={3} pb={8}>
              <Typography id="dialog-description" variant="caption">
                <List sx={{
                  'listStyleType': 'disc',
                  '& .MuiListItem-root': {
                    display: 'list-item',
                    pl: 0,
                    fontWeight: 'bold'
                  }
                }}
                >
                  <Stack spacing={2} p={3} pb={0}>
                    <ListItem>Easily capture students&apos; social dynamics in Teaching Groups</ListItem>
                    Simply assign the ready-to-use friendship survey to your Teaching Groups
                    <ListItem>Generate an actionable summary report with one-click</ListItem>
                    Quickly zoom in on well-connected students, cliques, social isolates & more
                  </Stack>
                </List>
              </Typography>

              <Box alignSelf="center" component="a" href={snaUrl}>
                <Button
                  variant="contained"
                  onClick={onDialogClose}
                  style={{ fontSize: '11px', fontWeight: 700 }}
                >
                  EXPLORE CONNECTO-GRAM
                </Button>
              </Box>
            </Stack>
          </Stack>
        )}
      </FeatureIntroDialog>
    </ThemeProvider>
  );
}

import { Box } from '@mui/material';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface SelectableProps {
  elementID: string;
  isSelected: boolean;
  onSelect: (elementID: string) => void;
  isLocked?: boolean;
  children: JSX.Element | Array<JSX.Element>;
}

export const Selectable = forwardRef<HTMLDivElement, SelectableProps>((props: SelectableProps, ref) => {
  const { elementID, isSelected, onSelect, children, isLocked } = props;

  return (
    <div
      ref={ref}
      className={classNames('selectable', { selected: isSelected, disabled: isLocked })}
      onClick={isLocked ? undefined : onClick}
    >
      {isLocked && (
        <Box position="absolute" top={16} right={16}>
          <LockOutlinedIcon fontSize="small" sx={{ color: 'text.disabled' }} />
        </Box>
      )}
      {children}
    </div>
  );

  function onClick(event: React.MouseEvent<HTMLDivElement>) {
    event.stopPropagation();
    onSelect(elementID);
  }
});

export default Selectable;

import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, BoxProps, Divider, styled, Typography } from '@mui/material';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem, { menuItemClasses } from '@mui/material/MenuItem';
import HTMLForm from 'components/pages/common/HTMLForm';
import React, { useState } from 'react';
import { GoogleAnalyticsEvent, logGoogleAnalyticsEvent } from '../../../../../utils/ga';

interface Props {
  deleteFormUrl: string,
  duplicateFormUrl: string,
  deleteCollaboratorAccessUrl: string,
  isFormCreator: boolean,
  onCopyFormLink: () => void;
  publishSettings: {
    toggleIsPublished: () => void;
    isPublished: boolean;
    isViewOnly: boolean;
  }
  disablePublish: boolean;
  id: string;
  menuButtonSize?: IconButtonProps['size'];
}

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  [`&.${menuItemClasses.root}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
    // add additional spacing to make the menu wider
    // without this, the menuitems (with their font-sizes)
    // would feel quite cramped
    paddingRight: theme.spacing(14)
  }
}));

export default function FormMenu(props: Props): React.ReactElement {
  const { onCopyFormLink, deleteFormUrl, duplicateFormUrl, deleteCollaboratorAccessUrl,
    isFormCreator, publishSettings, disablePublish, id, menuButtonSize = 'small'
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const onClickCopyLink = () => {
    onCopyFormLink();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={event => setAnchorEl(event.currentTarget)}
        size={menuButtonSize}
        id={id}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem key="copyFormLink" onClick={onClickCopyLink}>
          <IconWithText
            icon={<InsertLinkIcon />}
            text="Copy form link"
          />
        </StyledMenuItem>

        <HTMLForm action={duplicateFormUrl} method="POST">
          {({ onSubmit }) => (
            <StyledMenuItem onClick={() => handleDuplicateForm(onSubmit)}>
              <IconWithText
                icon={<ContentCopyOutlinedIcon />}
                text="Duplicate form"
              />
            </StyledMenuItem>
          )}
        </HTMLForm>

        <StyledMenuItem
          key="toggleIsPublished"
          onClick={() => {
            publishSettings.toggleIsPublished();
            setAnchorEl(null);
          }}
          disabled={publishSettings.isViewOnly || (!publishSettings.isPublished && disablePublish)}
        >
          {publishSettings.isPublished
            ? <IconWithText icon={<BlockOutlinedIcon />} text="Unpublish form" />
            : <IconWithText icon={<TaskAltOutlinedIcon />} text="Publish form" />}
        </StyledMenuItem>

        <Divider key="divider" />

        {
          isFormCreator && (
            <HTMLForm action={deleteFormUrl} method="DELETE">
              {({ onSubmit }) => (
                <StyledMenuItem onClick={() => handleDeleteForm(onSubmit)}>
                  <IconWithText
                    icon={<DeleteOutlineOutlinedIcon />}
                    text="Delete form"
                    boxColor={theme => theme.palette.error.dark}
                  />
                </StyledMenuItem>
              )}
            </HTMLForm>
          )
        }
        {
          !isFormCreator && (
            <HTMLForm action={deleteCollaboratorAccessUrl} method="DELETE">
              {({ onSubmit }) => (
                <StyledMenuItem onClick={() => handleDeleteCollaboratorAccess(onSubmit)}>
                  <IconWithText
                    icon={<DeleteOutlineOutlinedIcon />}
                    text="Remove form"
                    boxColor={theme => theme.palette.error.dark}
                  />
                </StyledMenuItem>
              )}
            </HTMLForm>
          )
        }
      </Menu>
    </div>
  );

  function handleDuplicateForm(onSubmit) {
    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.DuplicateForm);
    onSubmit();
  }

  function handleDeleteForm(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    logGoogleAnalyticsEvent(GoogleAnalyticsEvent.DeleteForm);
    onSubmit();
  }

  function handleDeleteCollaboratorAccess(onSubmit) {
    if (!window.confirm('Are you sure?')) return;

    onSubmit();
  }
}

function IconWithText(props: {
  icon: React.ReactElement,
  text: string,
  boxColor?: BoxProps['color']
}): React.ReactElement {
  const { icon, text, boxColor } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      columnGap={1}
      color={boxColor}
    >
      <Typography
        // the typography component is needed to
        // set the correct color for the icon,
        // but at the same time increases the height
        // of the icon (i.e. whitespace below the icon),
        // making the component not aligned
        // this centers the component
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        color={boxColor ?? (theme => theme.palette.text.secondary)}
      >
        {icon}
      </Typography>

      <Typography>{text}</Typography>
    </Box>
  );
}

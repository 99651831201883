import React, { useRef, useState } from 'react';
import { TemplateUsageInstructions } from 'components/models/FormTemplate';
import AudienceMetadata from '../../../../models/AudienceMetadata';
import FormProps, { FormConfigProps, FormErrors, FormUpdateResponseData, UpdateScheduleProps, UpdateScheduleResponse } from '../../../../models/FormProps';
import { addTask } from '../../../../utils/add_task';
import { getCsrfToken } from '../../../../utils/csrf';
import usePromptWindowUnload, { removePromptWindowUnload } from '../../../../utils/prompt_window_unload';
import { useDidUpdateEffect } from '../CustomHooks';
import Content from './Content';
import ExceedQuestionsLimitSnackbar from './ExceedQuestionsLimitSnackbar';
import { SEARCH_PARAM_SKIP_TO_ADD_PERSONALISE_FIELDS } from '../edit_audience/constants';
import useServerLogger, { LogEventType } from '../../../../utils/use_server_logger';

interface Props {
  form: FormProps & FormConfigProps;
  template: TemplateUsageInstructions;
  version: number;
  formPublishErrors: Array<string>;
  metadata: AudienceMetadata;
  metadataValuesWithHeaderUrl: string;
  hasAdditionalMetadata: boolean;
  canHaveAdditionalMetadata: boolean;
  updateUrl: string;
  updateScheduleUrl: string;
  updateFormImageUrl: string;
  previewUrl: string;
  formUrl: string;
  formsUrl: string;
  logUrl: string;
  createAnnouncementOnPgUrl: string;
  viewOnly: boolean;
  isShareableToPG: boolean;
}

export enum SaveStatus {
  Saved = 0,
  Saving,
  Error
}

export default function ContentContainer(props: Props): React.ReactElement {
  const { form, updateUrl, updateScheduleUrl, viewOnly, version, canHaveAdditionalMetadata, logUrl } = props;
  const { title, instructions, body, ...formConfig } = form;

  const saveTimerRef = useRef<number>();

  const [formState, setFormState] = useState<FormProps>({
    title, instructions, body
  });
  const [formConfigState, setFormConfigState] = useState<FormConfigProps>(formConfig);

  const formVersion = useRef<number>(version);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [saveStatus, setSaveStatus] = useState(SaveStatus.Saved);
  const [isExceedQuestionsLimitSnackbarOpen, setIsExceedQuestionsLimitSnackbarOpen] = useState(false);

  const sendLog = useServerLogger(logUrl);

  if (!viewOnly) useDidUpdateEffect(saveWithDelay, [formState]);
  usePromptWindowUnload(saveStatus !== SaveStatus.Saved);

  const onAddMetadata = canHaveAdditionalMetadata
    ? () => {
      sendLog({ event: LogEventType.Personalisation, trigger_from: 'add_more_fields' });
      window.location.href = `${updateUrl}/edit_audience?${SEARCH_PARAM_SKIP_TO_ADD_PERSONALISE_FIELDS}=1`;
    }
    : undefined;

  return (
    <>
      <Content
        {...props}
        form={{ title, instructions, body }}
        formConfig={formConfigState}
        onUpdateFormConfig={updateFormConfig}
        onUpdateSchedule={updateSchedule}
        formErrors={formErrors}
        saveStatus={saveStatus}
        onChangeForm={onChangeForm}
        setIsExceedQuestionsLimitSnackbarOpenCallback={() => setIsExceedQuestionsLimitSnackbarOpen(true)}
        onAddMetadata={onAddMetadata}
      />
      <ExceedQuestionsLimitSnackbar
        isSnackbarOpen={isExceedQuestionsLimitSnackbarOpen}
        setIsSnackbarOpenCallback={setIsExceedQuestionsLimitSnackbarOpen}
      />
    </>
  );

  function onChangeForm(form: FormProps) {
    setFormState(form);
  }

  function saveWithDelay() {
    setSaveStatus(SaveStatus.Saving);
    clearTimeout(saveTimerRef.current);
    saveTimerRef.current = window.setTimeout(save, 1000);

    return function cleanup() {
      clearTimeout(saveTimerRef.current);
    };
  }

  function save() {
    return addTask(updateFormTask);
  }

  function updateFormTask() {
    setSaveStatus(SaveStatus.Saving);
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        form: {
          ...formState,
          lock_version: formVersion.current
        }
      })
    };

    return fetch(updateUrl, options)
      .then(response => {
        if (response.redirected) {
          removePromptWindowUnload();
          window.location.href = response.url;
          return;
        }

        return response.json();
      })
      .then(data => {
        if (data) {
          setFormErrors(processErrors(data.errors));
          setSaveStatus(data.success ? SaveStatus.Saved : SaveStatus.Error);
          if (data.success) formVersion.current = data.version;
        }
      })
      .catch(e => {
        console.log(e);
        setSaveStatus(SaveStatus.Error);
      });
  }

  /**
   * This function is similar to the other update call, with the key difference being
   * that this takes the request data as an argument (instead of being triggered
   * by a state change), and does not amend of the formState variable
   * within this component.
   *
   * This makes it possible to for the caller of this to handle the loading states and
   * manage the FormConfigProps by themselves.
   */
  async function updateFormConfig(data: FormConfigProps): Promise<FormUpdateResponseData | null> {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        form: {
          ...formState,
          ...data,
          lock_version: formVersion.current
        }
      })
    };

    try {
      const response = await fetch(updateUrl, options);

      if (response.redirected) {
        window.location.href = response.url;
        return null;
      }

      const responseData: FormUpdateResponseData = await response.json();
      if (responseData.success) {
        formVersion.current = responseData.version;

        const { schedule, state, enable_email_copy_of_response } = responseData;
        setFormConfigState({
          schedule, state, enable_email_copy_of_response
        });
      }

      return responseData;
    } catch (e) {
      // no easy way to receive errors on the frontend, so doing this for now
      // eslint-disable-next-line no-console
      console.log(e);
      return null;
    }
  }

  async function updateSchedule(data: UpdateScheduleProps): Promise<UpdateScheduleResponse | null> {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: JSON.stringify({
        form: {
          ...data,
          lock_version: formVersion.current
        }
      })
    };

    try {
      const response = await fetch(updateScheduleUrl, options);

      if (response.redirected) {
        window.location.href = response.url;
        return null;
      }

      const responseData: UpdateScheduleResponse = await response.json();
      if (responseData.success) {
        formVersion.current = responseData.version;

        const { schedule, state } = responseData;
        setFormConfigState(prev => ({
          ...prev, schedule, state
        }));
      }

      return responseData;
    } catch (e) {
      // no easy way to receive errors on the frontend, so doing this for now
      // eslint-disable-next-line no-console
      console.log(e);
      return null;
    }
  }

  function processErrors(errors: FormErrors): FormErrors {
    return {
      ...errors,
      bodyByElementID: errors.body?.reduce((hash, element) => { hash[element.element_id] = element.errors; return hash; }, {}) // group body errors by element id
    };
  }
}

import { RootElement } from '../form_elements/RootElement';

export enum FormTemplateIcon {
  BooksWithApple = 'books_with_apple',
  MotherChildHand = 'mother_child_hand',
  Student = 'student',
  Team = 'team',
}

export interface SortableFormTemplate {
  title: string;
}

export interface TemplateUsageInstructions {
  usageInstructions: string | null;
  userGuideUrl: string | null;
  author: string | null;
}

export interface FormTemplatePreview extends SortableFormTemplate {
  id: number;
  description: string;
  icon: FormTemplateIcon;
  isNew: boolean;
  showFormTemplateUrl: string;
  author: string | null;
}

export interface FormTemplate extends SortableFormTemplate, TemplateUsageInstructions {
  formInstructions: string;
  body: RootElement;
}

import AppProvider from 'components/AppProvider';
import { FormTemplatePreview } from 'components/models/FormTemplate';
import React from 'react';
import FeedbackFormFab from '../common/FeedbackFormFab';
import Content from './Content';
import { FormData } from './forms_table/FormsTable';
import HomeLayout, { HomeLayoutProps } from './HomeLayout';
import ConnectogramTGModal from './modals/ConnectogramTGModal';
import StudentMimsLoginModal from './modals/StudentMimsLoginModal';

interface PageProps {
  aeFeedbackFormUrl: string;
  createNewFormUrl: string;
  enableShareToPg: boolean;
  enableSnaIntroModal: boolean;
  formsData: Array<FormData>;
  formTemplates: Array<FormTemplatePreview>;
  sideBarProps: HomeLayoutProps;
  userID: string;
}

export default function Page(props: PageProps): React.ReactElement {
  const { aeFeedbackFormUrl, enableSnaIntroModal, sideBarProps, userID } = props;
  const { enableSna, formTemplatesUrl, snaUrl } = sideBarProps;

  return (
    <AppProvider>
      <HomeLayout {...sideBarProps}>
        <Content
          enableSna={enableSna}
          formTemplatesUrl={formTemplatesUrl}
          snaUrl={snaUrl}
          {...props}
        />

        {renderFeaturesModal()}
        <FeedbackFormFab userID={userID} aeFeedbackFormUrl={aeFeedbackFormUrl} />
      </HomeLayout>
    </AppProvider>
  );

  function renderFeaturesModal() {
    if (enableSna && enableSnaIntroModal) return <ConnectogramTGModal userID={userID} snaUrl={snaUrl} />;
    return <StudentMimsLoginModal userID={userID} />;
  }
}

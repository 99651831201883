import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import { SaveStatus } from './ContentContainer';
import { AudienceType, FormErrors, StaffAudienceProps, StudentAudienceProps, School, AudienceProps, UntargetedStaffAudienceProps, UntargetedStudentAudienceProps } from '../../../../models/FormProps';
import AudienceTypeSelector from './AudienceTypeSelector';
import BreadcrumbHeader from './BreadcrumbHeader';
import PersonaliseAudienceContainer from './personalise_audience/PersonaliseAudienceContainer';
import FormVersionSnackBar from '../FormVersionSnackbar';
import TargetedUntargetedSelector from './targeted_untargeted_selector/TargetedUntargetedSelector';
import { isEmpty } from '../../../../utils/equality';
import { TabIndex } from '../EditTopBar';
import Footer from './Footer';
import { SEARCH_PARAM_SKIP_TO_ADD_PERSONALISE_FIELDS } from './constants';
import { LogEventType, type SendLogFuncType } from '../../../../utils/use_server_logger';
import StaffSelectorContent from './audience_selector/staff_selector/StaffSelectorContent';
import StudentSelectorContent from './audience_selector/student_selector/StudentSelectorContent';

interface Props {
  school: School;
  tabURLs: Array<string>;
  viewableAudience: AudienceProps;
  saveStatus: SaveStatus;
  setSaveStatusCallback: (saveStatus: SaveStatus) => void;
  changeAudienceCallback: (updatedAudience: AudienceProps) => void;
  personalisedFieldsTableUrl: string;
  clearPersonalisedFieldsUrl: string;
  downloadPersonalisedFieldsUrl: string;
  uploadPersonalisedFieldsUrl: string;
  formsUrl: string;
  getSchoolsUrl: string;
  getStudentRosterUrl: string;
  getStaffRosterUrl: string;
  formErrors: FormErrors;
  viewOnly: boolean;
  isHQUser: boolean;
  formHasResponses: boolean;
  enableTeachingGroups: boolean;
  sendLog: SendLogFuncType;
  integrationAudience?: AudienceType;
}

export enum AudienceStage {
  SelectAudienceType = 0,
  SelectedTargetedOrUntargetedAudience = 1,
  SelectIndividualsOrGroups = 2,
  PersonaliseFields = 3,
  GoToQuestions = 4
}

export default function Content(props: Props): React.ReactElement {
  const {
    school,
    tabURLs,
    viewableAudience,
    saveStatus,
    setSaveStatusCallback,
    changeAudienceCallback,
    personalisedFieldsTableUrl,
    clearPersonalisedFieldsUrl,
    downloadPersonalisedFieldsUrl,
    uploadPersonalisedFieldsUrl,
    formsUrl,
    getSchoolsUrl,
    getStudentRosterUrl,
    getStaffRosterUrl,
    formErrors,
    viewOnly,
    isHQUser,
    formHasResponses,
    enableTeachingGroups,
    sendLog,
    integrationAudience
  } = props;

  // used to allow users to directly navigate to the certain active step
  const searchParams = new URLSearchParams(window.location.search);
  const shouldSkipToPersonaliseFields = searchParams.get(SEARCH_PARAM_SKIP_TO_ADD_PERSONALISE_FIELDS);

  const [viewableAudienceState, setViewableAudienceState] = useState(viewableAudience);
  const [activeStep, setActiveStep] = useState(initialiseActiveStep(shouldSkipToPersonaliseFields));
  const [isFormVersionSnackbarOpen, setIsFormVersionSnackbarOpen] = useState(false);

  useEffect(() => {
    changeAudienceCallback(viewableAudienceState);
  }, [viewableAudienceState]);

  return (
    <Box className="manage-forms-edit-audience-page-content" display="flex" flexDirection="column" marginTop="100px">
      <BreadcrumbHeader
        formsUrl={formsUrl}
        activeStep={activeStep}
        saveStatus={saveStatus}
        viewableAudience={viewableAudienceState}
        setActiveStepCallback={setActiveStep}
        updateViewableAudienceCallback={updateViewableAudienceCallback}
        formErrors={formErrors}
        viewOnly={viewOnly}
        expectedAudience={integrationAudience}
      />
      <Stack paddingBottom="98px">
        {renderStepperContent()}
      </Stack>
      <FormVersionSnackBar
        isSnackbarOpen={isFormVersionSnackbarOpen}
        setIsSnackbarOpenCallback={setIsFormVersionSnackbarOpen}
      />
      <Footer
        viewableAudience={viewableAudienceState}
        activeStep={activeStep}
        setActiveStepCallback={setActiveStep}
        expectedAudience={integrationAudience}
      />
    </Box>
  );

  function renderStepperContent() {
    switch (activeStep) {
      case AudienceStage.SelectAudienceType:
        return (
          <AudienceTypeSelector
            viewableAudience={viewableAudienceState}
            viewOnly={viewOnly}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
            formHasResponses={formHasResponses}
            expectedAudience={integrationAudience}
          />
        );
      case AudienceStage.SelectedTargetedOrUntargetedAudience:
        return (
          <TargetedUntargetedSelector
            viewableAudience={viewableAudienceState as UntargetedStaffAudienceProps | UntargetedStudentAudienceProps}
            viewOnly={viewOnly}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
            formHasResponses={formHasResponses}
            expectedAudience={integrationAudience}
          />
        );
      case AudienceStage.SelectIndividualsOrGroups: {
        const onClickAddDataToPersonalise = () => {
          sendLog({ event: LogEventType.Personalisation, trigger_from: 'add_data_to_personalise' });
          setActiveStep(AudienceStage.PersonaliseFields);
        };

        if (viewableAudience.type === AudienceType.STAFF) {
          return (
            <StaffSelectorContent
              school={school}
              viewableAudience={viewableAudienceState as StaffAudienceProps}
              onClickAddDataToPersonalise={onClickAddDataToPersonalise}
              updateViewableAudienceCallback={updateViewableAudienceCallback}
              viewOnly={viewOnly}
              isHQUser={isHQUser}
              getSchoolsUrl={getSchoolsUrl}
              getStaffRosterUrl={getStaffRosterUrl}
            />
          );
        }

        return (
          <StudentSelectorContent
            school={school}
            viewableAudience={viewableAudienceState as StudentAudienceProps}
            onClickAddDataToPersonalise={onClickAddDataToPersonalise}
            updateViewableAudienceCallback={updateViewableAudienceCallback}
            viewOnly={viewOnly}
            isHQUser={isHQUser}
            getSchoolsUrl={getSchoolsUrl}
            getStudentRosterUrl={getStudentRosterUrl}
            enableTeachingGroups={enableTeachingGroups}
          />
        );
      }
      case AudienceStage.PersonaliseFields:
        // TODO: return undefined for removeAudienceMetadata when there hasn't been any previous uploads
        return (
          <PersonaliseAudienceContainer
            viewableAudience={viewableAudienceState as StaffAudienceProps | StudentAudienceProps}
            personalisedFieldsTableUrl={personalisedFieldsTableUrl}
            downloadPersonalisedFieldsUrl={downloadPersonalisedFieldsUrl}
            uploadPersonalisedFieldsUrl={uploadPersonalisedFieldsUrl}
            clearPersonalisedFieldsUrl={clearPersonalisedFieldsUrl}
            setIsFormVersionSnackbarOpen={setIsFormVersionSnackbarOpen}
            viewOnly={viewOnly}
            saveStatus={saveStatus}
            setSaveStatusCallback={setSaveStatusCallback}
          />
        );
      case AudienceStage.GoToQuestions:
        window.location.href = tabURLs[TabIndex.Questions];
        break;
      default:
        return null;
    }
  }

  function updateViewableAudienceCallback(updated: AudienceProps) {
    setViewableAudienceState(updated);
  }

  function initialiseActiveStep(skipToPersonaliseFieldParam: string | null) {
    const skipToPersonaliseFieldFlag = parseSearchParamFlag(skipToPersonaliseFieldParam);
    if (skipToPersonaliseFieldFlag && isSkipToPersonalisedFieldsAllowed(viewableAudience)) {
      return AudienceStage.PersonaliseFields;
    }

    if (viewableAudience.type === AudienceType.PUBLIC) {
      return AudienceStage.SelectAudienceType;
    }

    if (viewableAudience.type === AudienceType.UNTARGETED_STAFF || viewableAudience.type === AudienceType.UNTARGETED_STUDENT || isEmpty(viewableAudience.entities)) {
      return AudienceStage.SelectedTargetedOrUntargetedAudience;
    }

    return AudienceStage.SelectIndividualsOrGroups;
  }
}

function parseSearchParamFlag(input: string | null): boolean {
  return input === '1';
}

function isSkipToPersonalisedFieldsAllowed(viewableAudience: AudienceProps): boolean {
  return (viewableAudience.type === AudienceType.STAFF && !isEmpty(viewableAudience.entities))
    || (viewableAudience.type === AudienceType.STUDENT && !isEmpty(viewableAudience.entities));
}

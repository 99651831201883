import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

interface Props {
  message: string;
  showIcon?: boolean;
}

export default function NoEditMessageSeparator(props: Props): React.ReactElement {
  const { message, showIcon } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box sx={{ flexGrow: 1 }}>
        <Divider orientation="horizontal" variant="fullWidth" />
      </Box>
      <Box sx={{ flexShrink: 0, px: '16px', display: 'flex', gap: '8px' }}>
        {showIcon && <LockOutlinedIcon fontSize="small" sx={{ color: 'text.secondary' }} />}
        <Typography variant="body1" color="text.secondary">{message}</Typography>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Divider orientation="horizontal" variant="fullWidth" />
      </Box>
    </Box>
  );
}

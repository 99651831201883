import React, { CSSProperties, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import _ from 'lodash';
import {
  Clazz,
  Level,
  School,
  Student,
  StudentRoster,
  StudentAudienceProps,
  TeachingGroup,
  StudentEntityType,
  StaffEntityType
} from 'components/models/FormProps';
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Divider, Stack } from '@mui/material';
import { isEmpty } from 'components/utils/equality';
import DebouncedSearchbar from 'components/pages/manage/forms/common/DebouncedSearchbar';
import searchIgnoreCase from 'components/utils/search';
import ClassesSelector from './ClassesSelector';
import TeachingGroupsSelector from './TeachingGroupsSelector';
import StudentAudienceSearchResults, { SearchDataState } from './StudentAudienceSearchResults';

interface Props {
  studentRosters: Array<StudentRoster>;
  viewableAudience: StudentAudienceProps;
  addViewableEntityCallback: (viewableEntity: School | Level | Clazz | Student | TeachingGroup) => void;
  changeAcademicYearCallback: (year: number) => void;
  setSchoolIDCallback: (schoolID: number) => void;
  viewOnly: boolean;
  isHQUser: boolean;
  scrollContainerRef: React.MutableRefObject<HTMLDivElement>
}

// Common styles for both the class and TG accordion
const commonTopLevelAccordionStyle: CSSProperties = {
  boxShadow: 'none',
  border: '1px solid #d4d5d9',
  borderRadius: '4px'
};

const commonChildAccordionStyle: CSSProperties = {
  boxShadow: 'none',
  borderWidth: '0px',
  borderBottom: '1px solid #d4d5d9',
  borderRadius: '1px'
};

const SEARCHBAR_PLACEHOLDER = 'Search classes and groups';

export default function AudienceGroupSelector(props: Props): React.ReactElement {
  const { studentRosters, viewableAudience, addViewableEntityCallback, changeAcademicYearCallback, setSchoolIDCallback, viewOnly, isHQUser, scrollContainerRef } = props;
  const roster = studentRosters.find(r => r.year === viewableAudience.year);
  const { school, teachingGroups } = roster;
  const subjects: Array<string> = _.uniq(teachingGroups.map(tg => tg.subject_description));

  const [searchData, setSearchData] = useState<SearchDataState | null>(null);

  const onSearch = (searchString: string): void => {
    if (!searchString) {
      setSearchData(null);
      return;
    }

    const filteredClazzes = roster.clazzes.filter(clazz => searchIgnoreCase(clazz.name, searchString));
    const filteredTeachingGroups = roster.teachingGroups.filter(tg => searchIgnoreCase(tg.name, searchString));

    setSearchData({
      searchString,
      data: {
        clazzes: filteredClazzes,
        teachingGroups: filteredTeachingGroups
      }
    });
  };

  const entitiesSet: Set<string> = useMemo(() => {
    const res = new Set<string>();
    viewableAudience.entities.forEach(entity => {
      res.add(getKeyForEntity(entity.entity_type, entity.entity_id));
    });
    return res;
  }, [viewableAudience]);

  function isClassOrTgSelectable(
    entityType: StudentEntityType.TEACHING_GROUP | StudentEntityType.CLAZZ,
    entityId: number
  ): boolean {
    if (viewOnly) {
      return false;
    }
    if (isEmpty(viewableAudience.entities)) {
      return true;
    }

    const isEntityAlreadySelected = entitiesSet.has(getKeyForEntity(entityType, entityId));
    return isEntityAlreadySelected;
  }

  function handleChangeAcademicYear(event: React.ChangeEvent<HTMLInputElement>) {
    // allow confirm function until we decide on a better implementation
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (!confirm('Changing to this academic year will delete currently selected groups and students. Click to proceed.')) return;
    changeAcademicYearCallback(parseInt(event.target.value, 10));
    setSearchData(null);
  }

  return (
    <Stack
      spacing={3}
      divider={<Divider flexItem />}
    >
      <Stack spacing={2}>
        {isHQUser && (
          <Button
            className="school-back-button"
            size="small"
            disableRipple
            disableFocusRipple
            startIcon={<ArrowBackIosIcon />}
            onClick={() => setSchoolIDCallback(null)}
          >
            Back
          </Button>
        )}
        <Typography className="school-title">{school.name}</Typography>
        <AcademicYearSelector
          year={viewableAudience.year}
          handleChangeAcademicYear={handleChangeAcademicYear}
        />
      </Stack>

      <Stack key={viewableAudience.year} spacing={2}>
        <DebouncedSearchbar
          placeholder={SEARCHBAR_PLACEHOLDER}
          onSearch={onSearch}
          viewOnly={viewOnly}
        />

        {!searchData
          ? (
            <>
              <ClassesSelector
                studentRoster={roster}
                addViewableEntityCallback={addViewableEntityCallback}
                isClassSelectable={isClassOrTgSelectable}
                topLevelAccordionSx={commonTopLevelAccordionStyle}
                childAccordionSx={commonChildAccordionStyle}
              />
              <TeachingGroupsSelector
                subjects={subjects}
                studentRoster={roster}
                addViewableEntityCallback={addViewableEntityCallback}
                isSubjectChangeable={!viewOnly}
                isTgSelectable={isClassOrTgSelectable}
                topLevelAccordionSx={commonTopLevelAccordionStyle}
                childAccordionSx={commonChildAccordionStyle}
              />
            </>
          )
          : (
            <StudentAudienceSearchResults
              searchData={searchData}
              addViewableEntityCallback={addViewableEntityCallback}
              isClassOrTgSelectable={isClassOrTgSelectable}
              scrollContainerRef={scrollContainerRef}
            />
          )}
      </Stack>
    </Stack>
  );
}

function generateAcademicYears(): Array<number> {
  const latest = new Date().getFullYear() + 1;

  const supportedYears = [];
  for (let year = 2020; year <= latest; year++) {
    supportedYears.push(year);
  }

  return supportedYears;
}

const ACADEMIC_YEARS = generateAcademicYears();

function AcademicYearSelector(props: {
  year: number,
  handleChangeAcademicYear: (event: SelectChangeEvent<number>) => void | null
}): React.ReactElement {
  return (
    <FormControl fullWidth>
      <InputLabel>Academic Year</InputLabel>
      <Select<number>
        value={props.year}
        label="Academic Year"
        onChange={props.handleChangeAcademicYear}
      >
        {
          ACADEMIC_YEARS.map(year => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  );
}

function getKeyForEntity(
  entityType: StudentEntityType | StaffEntityType,
  entityId: number
): string {
  return `${entityType}###${entityId}`;
}
